import React, { createContext, useContext } from "react";
import useApi from "./useApi";

export interface WorkspaceData {
  id: string;
  slug: string;
  name: string;
  owner_id: string;
  api_key?: string;
}

interface WorkspaceContextValue {
  workspaces: WorkspaceData[];
  loadingWorkspaces: boolean;
  refreshWorkspaceData: () => {};
}

// Create a more specific type for the context
const WorkspaceContext = createContext<WorkspaceContextValue | undefined>(undefined);

export function WorkspaceProvider({ children }: { children: React.ReactNode }) {
  const { workspaces, loadingWorkspaces, refreshWorkspaceData } = useApi();

  return (
    <WorkspaceContext.Provider
      value={{
        workspaces,
        loadingWorkspaces: loadingWorkspaces ?? false,
        refreshWorkspaceData: async () => await refreshWorkspaceData,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspace(): WorkspaceContextValue {
  const context = useContext(WorkspaceContext);
  if (context === undefined) {
    throw new Error('useWorkspace must be used within a WorkspaceProvider');
  }
  return context;
}
