import { RealtimeChannel } from '@supabase/supabase-js';
import { supabase } from './supabase';

interface SubscriptionParams {
  channelName: string;
  eventHandler: (payload: any) => void;
  onConnectionChange?: (status: 'CONNECTED' | 'CONNECTING' | 'DISCONNECTED') => void;
}

const HEARTBEAT_INTERVAL = 30000; // 30 seconds
const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // 2 seconds

export const subscribeToChanges = ({ channelName, eventHandler, onConnectionChange }: SubscriptionParams) => {
  let heartbeatInterval: NodeJS.Timeout;
  let retryCount = 0;
  let isConnected = false;

  const startHeartbeat = (channel: RealtimeChannel) => {
    clearInterval(heartbeatInterval);
    heartbeatInterval = setInterval(async () => {
      try {
        // Send a heartbeat broadcast
        const status = await channel.send({
          type: 'broadcast',
          event: 'heartbeat',
          payload: { timestamp: Date.now() }
        });

        if (status !== 'ok' && isConnected) {
          console.log('heartbeat success')
          isConnected = false;
          onConnectionChange?.('DISCONNECTED');
          handleReconnect(channel);
        }
      } catch (error) {
        console.error('Heartbeat failed:', error);
        if (isConnected) {
          isConnected = false;
          onConnectionChange?.('DISCONNECTED');
          handleReconnect(channel);
        }
      }
    }, HEARTBEAT_INTERVAL);
  };

  const handleReconnect = async (channel: RealtimeChannel) => {
    if (retryCount >= MAX_RETRIES) {
      console.error('Max reconnection attempts reached');
      return;
    }

    retryCount++;
    onConnectionChange?.('CONNECTING');

    try {
      await channel.subscribe();
      isConnected = true;
      retryCount = 0;
      onConnectionChange?.('CONNECTED');
      startHeartbeat(channel);
    } catch (error) {
      console.error('Reconnection attempt failed:', error);
      // Exponential backoff for retry
      setTimeout(() => handleReconnect(channel), RETRY_DELAY * Math.pow(2, retryCount));
    }
  };

  try {
    const channel = supabase
      .channel(`public:${channelName}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: channelName
        },
        (payload) => {
          eventHandler(payload);
        }
      )
      /* .on('presence', { event: 'sync' }, () => {
        isConnected = true;
        onConnectionChange?.('CONNECTED');
        startHeartbeat(channel);
      }) */
      .on('system', {}, (payload) => {
        if (payload.message === 'Subscribed to PostgreSQL') {
          isConnected = true;
          onConnectionChange?.('CONNECTED');
          //startHeartbeat(channel);
        } else if (payload.message === 'Unsubscribed from PostgreSQL') {
          isConnected = false;
          onConnectionChange?.('DISCONNECTED');
          //handleReconnect(channel);
        }
      })
      .subscribe();

    // Clean up function
    const cleanup = () => {
      clearInterval(heartbeatInterval);
      if (channel) {
        unsubscribeFromChannel(channel);
      }
    };

    // Attach cleanup to channel
    (channel as any).cleanup = cleanup;

    return channel;
  } catch (error) {
    console.error(`Error subscribing to ${channelName}:`, error);
    onConnectionChange?.('DISCONNECTED');
    return null;
  }
};

export const unsubscribeFromChannel = async (channel: RealtimeChannel) => {
  try {
    // Call cleanup if it exists
    if ((channel as any).cleanup) {
      (channel as any).cleanup();
    }
    await supabase.removeChannel(channel);
  } catch (error) {
    console.error('Error unsubscribing from channel:', error);
  }
}; 