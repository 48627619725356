import Sidebar from "../components/sidebar/sidebar";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { blue } from "@mui/material/colors";
import Mlogo from "../assets/Hidden.svg";
import imgmockup from "../assets/mockup.png";
import dropbox from "../assets/dropbox.png";
import members from "../assets/members.svg";
import sort from "../assets/sort.png";

import checkimg from "../assets/check_icon.svg";
import cheat from "../assets/cheat.svg";
import linkicon from "../assets/link-icon.svg";
import usergroup from "../assets/user-group.svg";
import users2 from "../assets/users-2.png";
import users3 from "../assets/users-3.png";
import users4 from "../assets/users-4.png";
import users5 from "../assets/users-5.png";
import users6 from "../assets/users-6.png";
import users7 from "../assets/users-7.png";
import Icons from "../assets/icons.svg";

import option from "../assets/option.svg";

import ProgressBar from "@ramonak/react-progress-bar";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
// import BottomBar from "../components/bottom-bar";
import { Link } from "react-router-dom";
import { getUser } from "../hooks/user";
import CreateProject from "./createProject";
import PathConstants from "../pathConstants";
interface projects {
  name: string;
  img: string;
  checks: string;
  chats: number;
  links: number;
  date: string;
  users: string;
}

const Projects = () => {
  const { projectsLoading, projects } = getUser();
  projects.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const handleChange = () => {};

  const items = [
    {
      id: 1,
      name: "Projects",
    },
    {
      id: 2,
      name: "Pending",
    },
    {
      id: 3,
      name: "Done",
    },
    {
      id: 4,
      name: "Archieved",
    },
  ];

  return projectsLoading ? (
    <></>
  ) : projects.length === 0 ? (
    <CreateProject />
  ) : (
    <main className="flex">
      <div className="lg:w-[80%] w-full">
        <div className="project-content  w-[90%] mx-auto">
          <div className="grid items-center  gap-2  py-5 grid-cols-12 ">
            <div className="flex sm:gap-3  overflow-x-scroll lg:overflow-hidden pb-1 sm:col-span-6 col-span-10 gap-1 ">
              {/* Filters */}
              {items.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={
                      activeTab === item.id
                        ? "text-white active tab  py-2 shadow hover:shadow-lg  sm:px-4 px-3 bg-mainColor rounded-lg"
                        : "tab text-textColor dark:text-darkTextActiveColor hover:shadow-lg rounded-lg  hover:bg-black py-2   hover:text-[#fff] sm:px-4 px-2"
                    }
                    onClick={() => handleTabClick(item.id)}
                  >
                    <p className="cursor-pointer   font-bold text-[12px]   ">
                      {item.name}
                    </p>
                  </button>
                );
              })}
            </div>
            <div className="sm:grid  justify-items-end hidden sm:col-span-6	col-span-2  xl:w-[60%]  lg:w-[70%] md:w-[80%]  w-[60%] ml-auto gap-2 grid-cols-1">
              {/* <div className="flex w-fit  flojustify-end	at-right items-center gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                <img src={sort} alt="" className="w-[12px] h-[12px]" />
                <p className="font-RobotoFlexRegular sm:block hidden font-[700] text-[12px] text-black">
                  Sort: A-Z
                </p>
              </div> */}
              {/* <div className="sm:flex w-fit hidden gap-2 border-[1px] border-[#CFDBD5] rounded-lg px-4 py-2 cursor-pointer">
                <img src={option} alt="" className="w-[10%]" />
                <p className="font-RobotoFlexRegular font-[700] text-[12px] text-black">
                  Bulk Actions
                </p>
              </div> */}
            </div>
            <div className=" sm:hidden   block  col-span-2">
              <div className="border-[2px] py-2  border-[#CFDBD5] rounded">
                <img
                  src={sort}
                  alt=""
                  className="w-[12px] mx-auto sm:hidden block h-[12px]"
                />
              </div>
            </div>
          </div>

          {/* Tabs Content */}
          <div className="grid mb-10 lg:mb-2 grid-cols-1 overflow-y-scroll h-[100vh]">
            {activeTab === 1 && (
              <ul className="h-[400px]">
                {projects.map((item: any, index: number) => {
                  const numberOfTask = item.project_tasks?.length || 0;
                  const numberOfMembers = item.project_members?.length || 0;
                  return (
                    <li key={index} className="mb-2">
                      <div className="bg-white dark:bg-[#000] border-[1px] border-borderColor dark:border-darkBorderColor grid items-center hover:drop-shadow-sm cursor-pointer py-2 px-4 rounded-[6px] grid-cols-12 py-5">
                        <div className="sm:col-span-7  col-span-12">
                          <div>
                            <ul className="max-w-md col-span-12 flex gap-2 items-center space-y-1 text-gray-500 list-inside dark:text-gray-400">
                              <li className="sm:block hidden">
                                <Checkbox
                                  id="remember"
                                  aria-describedby="remember"
                                  onChange={handleChange}
                                  color="error"
                                  className="de_checkbox"
                                  sx={{
                                    color: blue,
                                    "&.Mui-checked": {
                                      color: blue,
                                    },
                                    "&:hover": {
                                      padding: "0px",
                                      backgroundColor: "transparent",
                                    },
                                    padding: "0px",
                                    background: "transparent",
                                  }}
                                />
                              </li>
                              <li className="hover:drop-shadow-lg flex gap-2 items-center font-bold text-textColor dark:text-darkTextColor text-[14px]">
                                {/* <img
                                  src={dropbox}
                                  alt=""
                                  className="w-[32px] "
                                /> */}
                                <div className=" justify-center">
                                  <Link
                                    to={PathConstants.projectPages
                                      .replace(":slug", item.slug)
                                      .replace(":page", "issues")}
                                  >
                                    {item.name}
                                  </Link>
                                  <li className="sm:hidden w-[50%] block hover:drop-shadow-lg">
                                    <ProgressBar
                                      completed={60}
                                      maxCompleted={100}
                                      height="5px"
                                      bgColor="#81DE4A"
                                      className="my-2"
                                      labelSize={"0"}
                                    />
                                  </li>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-span-4 xl:block hidden ">
                          <ul className="flex items-center gap-4">
                            <li className="w-[15%] hover:drop-shadow-lg">
                              {/* <ProgressBar
                                completed={60}
                                height="5px"
                                bgColor="#81DE4A"
                                className="my-2"
                                labelSize={"0"}
                              /> */}
                            </li>
                            <li className="w-fit">
                              <div className="flex bg-[#F5F5FA] hover:drop-shadow-lg py-1  rounded-full px-3 gap-1 items-center">
                                <span>
                                  <img src={checkimg} alt="" />
                                </span>
                                <h2 className=" font-bold text-[12px]  text-[#5F646D]">
                                  {item.checks} 0/{numberOfTask}
                                </h2>
                              </div>
                            </li>
                            <li className="w-fit hover:drop-shadow-lg">
                              <div className="flex bg-[#F5F5FA] py-1  rounded-full px-3 gap-1 items-center">
                                <span>
                                  <img src={members} alt="" width="16px" />
                                </span>
                                <h2 className="font-bold text-[12px]  text-[#5F646D]">
                                  {item.checks} {numberOfMembers}
                                </h2>
                              </div>
                            </li>
                            {/* <li className="w-fit hover:drop-shadow-lg">
                              <div className="flex bg-[#F5F5FA] dark:bg-darkBorderColor py-1  rounded-full px-3 gap-1 items-center">
                                <h2 className="font-bold text-[12px]  text-[#5F646D]">
                                  {new Date(item.created_at).toDateString()}
                                </h2>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-span-1 xl:block hidden">
                          <ul className="flex items-center  float-right ">
                            <li className="w-fit  hover:shadow-lg ">
                              <div className="flex py-1   rounded-full px-3 gap-1 items-center">
                                <img src={option} alt="" />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {/* <BottomBar /> */}
    </main>
  );
};
export default Projects;
