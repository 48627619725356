// import BottomBar from "../components/bottom-bar";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/input/input";
import { useEffect, useState } from "react";
import Card from "../../components/card";
import { createWorkspace } from "../../api/supabase";
import { getUser } from "../../hooks/user";

export default function Onboarding() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = getUser();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceUrl, setWorkspaceUrl] = useState("");

  // update workspaceUrl as user types
  useEffect(() => {
    setWorkspaceUrl(workspaceName.toLowerCase().replace(/\s/g, "-"));
  }, [workspaceName]);

  const handleTextChange = (e: any) => {
    const formattedValue = e.target.value.replace(
      window.location.host + "/",
      ""
    );
    e.target.id === "workspaceName" && setWorkspaceName(formattedValue);
    e.target.id === "workspaceUrl" && setWorkspaceUrl(formattedValue);
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-lg w-full space-y-8 p-8 bg-mainBg rounded-lg shadow border border-borderColor dark:border-darkBorderColor">
      <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-textColor dark:text-darkTextActiveColor">
            Welcome! Let's get started
          </h2>
          <p className="mt-2 text-center text-sm text-textColor dark:text-darkTextActiveColor">
            Create your workspace to continue
          </p>
        </div>
        <Card classes="border-0 py-0">
          <label
            htmlFor="remember"
            className="text-textColor dark:text-darkTextActiveColor font-bold"
          >
            Workspace Name
          </label>
          <Input
            id="workspaceName"
            type="text"
            placeholder=""
            classes="my-2 mb-5"
            predefinedText=""
            handleChange={(e: any) => handleTextChange(e)}
          />

          {/* <label
            htmlFor="remember"
            className="text-textColor dark:text-darkTextActiveColor font-bold"
          >
            Workspace URL
          </label>
          <Input
            disabled={true}
            id="workspaceUrl"
            type="text"
            placeholder={workspaceUrl}
            classes="my-2 mb-5"
            predefinedText={"~ project/"}
            handleChange={(value: string) => handleTextChange(value)}
            urlSafe={true}
          /> */}

          <button
            disabled={(workspaceName === "" || workspaceUrl === "") && workspaceName.length < 5}
            className="w-full text-darkTextActiveColor font-RobotoFlexRegular bg-[#0052FF] hover:shadow-md py-4 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5  text-center disabled:bg-blue/90 disabled:cursor-not-allowed"
            onClick={async () => {
              await createWorkspace(
                { name: workspaceName, slug: workspaceUrl, owner_id: user.id },
                navigate
              );
            }}
          >
            Create Workspace
          </button>
        </Card>
      </div>
    </div>
  );
}
