import styles from "./kanban.module.scss";
import moveLeft from "../../assets/moveLeft.svg";
import moveRight from "../../assets/moveRight.svg";
import { api, updateTaskStatuses } from "../../helpers/api";
import { useEffect, useRef, useState } from "react";
import { supabase } from "../../helpers/supabase";

export const KanbanColumn = ({
  status,
  statusTasks,
  taskStatusesRef,
  setTaskStatuses,
  openModal,
}: {
  status: any;
  statusTasks: any;
  taskStatusesRef: any;
  setTaskStatuses: (statuses: any) => void;
  openModal: (status_id: any) => void;
}) => {
  const [color, setColor] = useState(status.color);
  const [showInput, setShowInput] = useState(false);
  const [editId, setEditId] = useState(null);
  const [inputValue, setInputValue] = useState(status.status_name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Set Focus State when input is shown
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  /* ======================== */
  /* ==== Move Column ====== */
  /* ======================== */
  const moveColumn = async ({
    status_id,
    direction,
  }: {
    status_id: any;
    direction: string;
  }) => {
    const status = taskStatusesRef.current.find(
      (status: any) => status.status_id === status_id
    );

    const newOrder = direction === "left" ? status.order - 1 : status.order + 1;

    // Update the order of the other statuses
    const updatedStatuses = taskStatusesRef.current.map((status: any) => {
      if (status.status_id === status_id) {
        return { ...status, order: newOrder };
      }
      if (direction === "left") {
        if (status.order === newOrder) {
          return { ...status, order: status.order + 1 };
        }
      }
      if (direction === "right") {
        if (status.order === newOrder) {
          return { ...status, order: status.order - 1 };
        }
      }
      return status;
    });

    // Update the order of the selected column
    setTaskStatuses(updatedStatuses);

    // Save the new order to the database
    const { error } = await supabase.rpc("update_column_order", {
      column_id: status_id,
      new_order: newOrder
    });

    if (error) {
      console.error("error", error);
    }
    
    return;
  };

  const handleNameChange = (status: any) => {
    setEditId(status.status_id);
    setShowInput(!showInput);
  };

  const handleInput = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleColor = (e: any) => {
    setColor(e.target.value);
  };

  const clearInput = () => {
    setShowInput(false);
    setInputValue(status?.status_name);
    setColor(status?.color);
  };

  const saveColumnName = async () => {
    const updatedStatuses = taskStatusesRef.current.map((status: any) => {
      if (status.status_id === editId) {
        return { ...status, status_name: inputValue, color: color };
      }
      return status;
    });

    setTaskStatuses(updatedStatuses);

    await updateTaskStatuses({
      statuses: updatedStatuses,
    });

    setShowInput(false);
    setInputValue("");
  };

  return (
    <div className="flex font-bold text-left items-center w-full">
      <h3
        className="text-[18px]"
        onClick={() => handleNameChange(status)}
        hidden={showInput}
      >
        {status?.status_name}
      </h3>

      {showInput && (
        <div className="text-black w-full">
          <input
            className="text-white text-[18px] w-full rounded-md bg-transparent border-b-2 border-borderColor dark:border-bgDark focus-visible:outline-none"
            type="text"
            placeholder={status?.status_name}
            value={inputValue}
            onChange={(e) => handleInput(e)}
            ref={inputRef}
          />

          <input
            className="text-white text-[18px] w-full rounded-md bg-transparent border-b-2 border-borderColor dark:border-bgDark focus-visible:outline-none"
            type="color"
            placeholder={status?.color}
            value={color}
            onChange={(e) => handleColor(e)}
          />

          <div className="flex gap-2 mt-2">
            <button
              className="w-full bg-red-500 text-white rounded-md py-1 px-4 m-auto font-RobotoFlexRegular font-bold text-[14px] cursor-pointer"
              onClick={() => clearInput()}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#0052FF] text-white rounded-md py-1 px-4 m-auto font-RobotoFlexRegular font-bold text-[14px] cursor-pointer"
              onClick={() => saveColumnName()}
              disabled={(inputValue === "" || inputValue === status?.status_name) && (inputValue === "" || color === status?.color)}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {!showInput && statusTasks?.length > 0 ? (
        <span className={`text-white text-[10px] font-black px-2 py-1 ml-1 rounded-full bg-${status.color ? "["+status.color+"]" : 'blue'}`} style={{background: status.color}}>
          {statusTasks?.length}
        </span>
      ) : null}

      {!showInput && (
        <>
          <div className={styles.moveButton}>
            <button
              disabled={status.order <= 1}
              onClick={() =>
                moveColumn({
                  status_id: status.status_id,
                  direction: "left",
                })
              }
            >
              <img src={moveLeft} alt="" className="w-[24px]" />
            </button>
            <button
              disabled={status.order === taskStatusesRef?.current?.length}
              onClick={() =>
                moveColumn({
                  status_id: status.status_id,
                  direction: "right",
                })
              }
            >
              <img src={moveRight} alt="" className="w-[24px]" />
            </button>
          </div>

          <div
            onClick={(e) => openModal(e.currentTarget.id)}
            className="bg-black dark:bg-bgDark hover:bg-mainColor dark:hover:bg-mainColor text-white border border-borderColor dark:border-bgDark rounded-full cursor-pointer px-[6px]"
            id={status.status_id}
          >
            <p className="font-bold">
              {/* <img src={iconplus} alt="" className="w-[15%]" /> */}+
            </p>
          </div>
        </>
      )}
    </div>
  );
};
