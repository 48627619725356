import "./App.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Signup from "./pages/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import Projects from "./pages/projects";
import Inbox from "./pages/inbox";
import Chatscreen from "./pages/chatscreen";
import EmptyKanBanDesk from "./pages/kanban-empty";
import Discussions from "./pages/discussions";
import MyIssuesEmpty from "./pages/myIssuesEmpty";
import CreateWorkspace from "./pages/createProject";
import { KanBanDeskPage } from "./pages/kanban";
import Login from "./pages/login";
import { useWorkspace } from "./context/workspace";
import CRMContacts from "./pages/crm/contacts";
import Sidebar from "./components/sidebar/sidebar";
import Navbar from "./components/navbar";
import { supabase } from "./helpers/supabase";
import ProtectedRoute from "./ProtectedRoute";
import { NotFoundPage } from "./pages/NotFound";
import { RenewPlan } from "./pages/RenewPlan";
import { useUser } from "./context/userInfo";
import ErrorLogs from "./pages/ErrorLogs";
import Onboarding from "./pages/onboarding/onboarding";
import { posthog } from "posthog-js";
import ProjectSettings from "./pages/settings/ProjectSettings";

function App() {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { workspaces, loadingWorkspaces } = useWorkspace();
  const savedWorkspace = sessionStorage.getItem("workspace");
  const [session, setSession] = useState<any>(null);
  const [hasWorkspace, setHasWorkspace] = useState<boolean | null>(null);
  /* const rejectPromise = new Promise((resolve, reject) => {
    reject(new Error("This promise is intentionally rejected."));
  });

  // throw an error
  useEffect(() => {
    // Start the promise chain but don't handle the rejection
    rejectPromise.then(() => console.log("This won't happen because the promise is rejected."));
  }, []);
 */
  // Initialize PostHog
  useEffect(() => {
    const POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY;
    if (POSTHOG_KEY) {
      posthog.init(POSTHOG_KEY, {
        api_host:
          process.env.REACT_APP_POSTHOG_HOST || "https://us.i.posthog.com",
        person_profiles: "identified_only",
      });
    }
  }, []);

  // Set dark mode
  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session: _session },
        error,
      } = await supabase.auth.getSession();

      if (!session && !error) {
        return _session;
      }
      return null;
    };

    checkSession().then((sessionData) => {
      if (!sessionData) {
        if (location.pathname !== "/" && location.pathname !== "/signup") {
          navigate("/");
        }
      } else {
        setIsLoggedIn(true);
        setSession(sessionData);
      }
    });
  }, []);

  useEffect(() => {
    // Add workspace check
    const checkWorkspace = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user && workspaces) {
        setHasWorkspace(workspaces && workspaces.length > 0);
      }
    };

    checkWorkspace();
  }, [session]);

  // Check authentication
  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      return !!(session && !error);
    };

    checkSession().then((isLoggedIn) => {
      if (!isLoggedIn) {
        if (location.pathname !== "/" && location.pathname !== "/signup") {
          navigate("/");
        }
      } else {
        setIsLoggedIn(true);
      }
    });
  }, [location.pathname, navigate]);

  // Check workspaces
  useEffect(() => {
    if (!loadingWorkspaces && workspaces?.length === 0) {
      navigate("/Onboarding");
    } else if (workspaces?.length > 0) {
      const currentWorkspace =
        workspaces?.length > 0 && savedWorkspace
          ? workspaces?.find(
              (workspace: any) => workspace.id === savedWorkspace
            )
          : workspaces[0];

      //console.log("Current workspace:", currentWorkspace);

      if (currentWorkspace) {
        sessionStorage.setItem("workspace", currentWorkspace.id);
        if (location.pathname === "/") {
          console.log("Navigation state:", {
            user,
            currentWorkspace,
            workspaces,
          });
        }
      }
    }
  }, [
    isLoggedIn,
    workspaces,
    savedWorkspace,
    loadingWorkspaces,
    location.pathname,
    navigate,
  ]);

  // Check user plan
  useEffect(() => {
    if (user.user_plan?.status === "expired") {
      navigate("/renew");
    }
  }, [user, navigate]);

  const router = [
    {
      path: "/",
      requiresAuth: false,
      element: <Login />,
    },
    {
      path: "/signup",
      requiresAuth: false,
      element: <Signup />,
    },
    {
      path: "/:slug/overview",
      requiresAuth: true,
      element: <Dashboard />,
    },
    {
      path: "/:slug/contacts",
      requiresAuth: true,
      element: <CRMContacts />,
    },
    /* ======================== */
    /* ==== Workspaces Start === */
    /* ======================== */
    {
      path: "/workspaces",
      requiresAuth: true,
      element: <Projects />,
    },
    {
      path: "/create-workspace",
      requiresAuth: true,
      element: <CreateWorkspace />,
    },
    {
      path: "/:slug/:page",
      requiresAuth: true,
      element: <KanBanDeskPage />,
    },
    /* ======================== */
    /* ==== Workspaces End ===== */
    /* ======================== */
    {
      path: "/inbox",
      requiresAuth: true,
      element: <Inbox />,
    },
    {
      path: "/chat",
      requiresAuth: true,
      element: <Discussions />,
    },
    {
      path: "/chatscreen",
      requiresAuth: true,
      element: <Chatscreen />,
    },
    {
      path: "/empty-kanbandesk",
      requiresAuth: true,
      element: <EmptyKanBanDesk />,
    },
    {
      path: "/my-issues-empty",
      requiresAuth: true,
      element: <MyIssuesEmpty />,
    },
    {
      path: "/renew",
      requiresAuth: true,
      element: <RenewPlan />,
    },
    {
      path: "/oops",
      requiresAuth: false,
      element: <NotFoundPage />,
    },
    {
      path: "/:slug/error-logs",
      requiresAuth: true,
      element: <ErrorLogs />,
    },
    {
      path: "/onboarding",
      requiresAuth: false,
      element: <Onboarding />,
    },
    {
      path: "/:slug/settings",
      requiresAuth: true,
      element: <ProjectSettings />,
    },
  ];

  return (
    <>
      {isLoggedIn && (
        <div className="hidden md:block sidebar">
          <Sidebar setIsOpen={() => {}} />
        </div>
      )}

      <div
        className={
          isLoggedIn ? "content lg:w-full overflow-x-scroll" : "w-full"
        }
      >
        {isLoggedIn && workspaces.length !== 0 && (
          <Navbar
            title={
              workspaces.length > 0 && savedWorkspace
                ? workspaces?.find(
                    (workspace: any) => workspace.id === savedWorkspace
                  )?.name
                : null
            }
            hideActions={false}
          />
        )}
        <div className="lg:pt-20 pt-0 h-full">
          <Routes>
            {router.map((route, index) =>
              route.requiresAuth ? (
                <Route
                  key={index}
                  path={route.path}
                  element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                />
              ) : (
                <Route key={index} path={route.path} element={route.element} />
              )
            )}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
