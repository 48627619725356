import React from 'react';
import { useWorkspace } from '../../context/workspace/useContext';
import { Link } from 'react-router-dom';
import { supabase } from '../../helpers/supabase';

const ProjectSettings = () => {
  const { workspaces, refreshWorkspaceData } = useWorkspace();
  const savedWorkspaceId = sessionStorage.getItem("workspace");
  
  const currentWorkspace = workspaces?.find(
    workspace => workspace.id === savedWorkspaceId
  ) || workspaces[0];

  if (!currentWorkspace) {
    return (
      <div className="p-8 text-center">
        <p className="text-gray-600 dark:text-gray-300">No workspace found</p>
      </div>
    );
  }

  const generateApiKey = async () => {
    try {
      // Generate a random API key
      const apiKey = 'et_' + Array.from(crypto.getRandomValues(new Uint8Array(32)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      // Update the workspace with the new API key
      const { data, error } = await supabase
        .from('projects')
        .update({ api_key: apiKey })
        .eq('id', currentWorkspace.id);

        console.log({data, error, currentWorkspace})

      if (error) throw error;
      
      // Refresh the workspace data
      // You'll need to implement this in your workspace context
      //await refreshWorkspaceData();
      
    } catch (error) {
      console.error({error});
      // Add proper error handling/notification here
    }
  };

  return (
    <div className="p-8 max-w-4xl">
      <h1 className="text-2xl font-bold mb-8 text-gray-800 dark:text-white">
        {currentWorkspace.name} Settings
      </h1>
      
      <div className="space-y-8">
        {/* Main Settings Sections */}
        <div className="space-y-8">
          {/* General Settings Section */}
          <section>
            <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">
              General
            </h2>
            <div className="bg-white dark:bg-mainBg rounded-lg p-6 shadow-sm border border-borderColor dark:border-darkBorderColor">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Project Name
                </label>
                <input 
                  type="text" 
                  value={currentWorkspace.name}
                  className="w-full px-3 py-2 border border-borderColor dark:border-darkBorderColor rounded-lg 
                           bg-white dark:bg-dark text-gray-700 dark:text-white
                           focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Project URL
                </label>
                <input 
                  type="text" 
                  value={currentWorkspace.slug} 
                  disabled
                  className="w-full px-3 py-2 border border-borderColor dark:border-darkBorderColor rounded-lg 
                           bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 cursor-not-allowed"
                />
              </div>
            </div>
          </section>

          {/* Team Management Section */}
          <section>
            <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">
              Team
            </h2>
            <div className="bg-white dark:bg-mainBg rounded-lg p-6 shadow-sm border border-borderColor dark:border-darkBorderColor">
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                Manage your project team members and their permissions
              </p>
              <Link 
                to={`/${currentWorkspace.slug}/team`}
                className="inline-block px-4 py-2 bg-[#0052FF] text-white rounded-lg hover:bg-blue-600 
                         transition-colors duration-200"
              >
                Manage Team
              </Link>
            </div>
          </section>

          {/* Notifications Section */}
          <section>
            <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">
              Notifications
            </h2>
            <div className="bg-white dark:bg-mainBg rounded-lg p-6 shadow-sm border border-borderColor dark:border-darkBorderColor">
              <div className="space-y-4">
                <label className="flex items-center space-x-3">
                  <input 
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 
                             focus:ring-blue-500 dark:border-gray-600 dark:bg-dark"
                  />
                  <span className="text-gray-700 dark:text-gray-300">Email Notifications</span>
                </label>
                <label className="flex items-center space-x-3">
                  <input 
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 
                             focus:ring-blue-500 dark:border-gray-600 dark:bg-dark"
                  />
                  <span className="text-gray-700 dark:text-gray-300">Push Notifications</span>
                </label>
              </div>
            </div>
          </section>

          {/* API Key Section */}
          <section>
            <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">
              Error Tracking
            </h2>
            <div className="bg-white dark:bg-mainBg rounded-lg p-6 shadow-sm border border-borderColor dark:border-darkBorderColor">
              <div className="space-y-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Project API Key
                  </label>
                  <div className="flex items-center space-x-2">
                    <input 
                      type="text" 
                      value={currentWorkspace?.api_key || 'No API key generated'}
                      readOnly
                      className="flex-1 px-3 py-2 border border-borderColor dark:border-darkBorderColor rounded-lg 
                               bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
                    />
                    <button
                      onClick={generateApiKey}
                      className="px-4 py-2 bg-[#0052FF] text-white rounded-lg hover:bg-blue-600 
                               transition-colors duration-200"
                    >
                      Generate New Key
                    </button>
                  </div>
                </div>
                
                <div className="mt-4">
                  <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Installation Instructions
                  </h3>
                  <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
                      1. Add this script to your HTML:
                    </p>
                    <code className="block bg-gray-100 dark:bg-gray-900 p-2 rounded text-sm">
                      {`<script src="https://your-domain.com/error-tracker.js"></script>`}
                    </code>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-4 mb-2">
                      2. Initialize the tracker:
                    </p>
                    <code className="block bg-gray-100 dark:bg-gray-900 p-2 rounded text-sm">
                      {`ErrorTracker.init({
  projectId: "${currentWorkspace.id}",
  apiKey: "${currentWorkspace.api_key ?? "your-api-key"}"
});`}
                    </code>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Danger Zone Section - Separated */}
        <section className="mt-12 pt-8 border-t border-borderColor dark:border-darkBorderColor">
          <h2 className="text-xl font-semibold mb-4 text-red-600 dark:text-red-500">
            Danger Zone
          </h2>
          <div className="bg-white dark:bg-mainBg rounded-lg p-6 shadow-sm border-2 border-red-500 dark:border-red-600">
            <div className="space-y-6">
              <div className="flex items-center justify-between pb-4 border-b border-gray-200 dark:border-gray-700">
                <div>
                  <h3 className="text-gray-700 dark:text-gray-300 font-medium">Archive Project</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    Temporarily hide this project and all its contents
                  </p>
                </div>
                <button className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 
                                 transition-colors duration-200">
                  Archive
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-gray-700 dark:text-gray-300 font-medium">Delete Project</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    Permanently delete this project and all its contents
                  </p>
                </div>
                <button className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 
                                 transition-colors duration-200">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectSettings; 