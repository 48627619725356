import { EditorContent, useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Code from "@tiptap/extension-code";
import Document from "@tiptap/extension-document";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Paragraph from "@tiptap/extension-paragraph";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import { ColorHighlighter } from "./ColorHighlighter";
import { useEffect } from "react";

export const Editor = ({
  defaultContent,
  setTaskDescription,
}: {
  defaultContent?: string;
  setTaskDescription: (content: string) => void;
}) => {

  /* ======================== */
  /* ==== Handle Editor ===== */
  /* ======================== */
  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Typography.configure({
        // You can customize typography rules here
        oneHalf: false, // Example: Disable one half rule
      }),
      Highlight,
      ColorHighlighter,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        // Use a placeholder:
        placeholder: "Write something …",
        // Use different placeholders depending on the node type:
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'What’s the title?'
        //   }

        //   return 'Can you add some further context?'
        // },
      }),
    ],
  });

  useEffect(() => {
    editor?.on("blur", ({ editor }) => {
      const content = editor.getHTML();
      setTaskDescription(content);
    });

    // clear editor
    return () => {
      editor?.commands.setContent("");
    };
  }, [editor]);

  useEffect(() => {
    if (defaultContent) {
      editor?.commands.setContent(defaultContent);
    }

    return () => {
      editor?.commands.setContent("");
    };
  }, [defaultContent]);

  return (
    <div className="editor">
      <EditorContent
        editor={editor}
        className="min-h-[150px] w-full py-0 text-[14px]  outline-none text-white font-medium bg-transparent"
      />
    </div>
  );
};
