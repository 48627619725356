import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { Fab, Input } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useDraggable } from "react-use-draggable-scroll";
import styles from "./kanban.module.scss";
import { motion, AnimatePresence } from "framer-motion";

import TaskModel from "../../components/modal/TaskModal";
import trash from "../../assets/trash.svg";
import moveLeft from "../../assets/moveLeft.svg";
import moveRight from "../../assets/moveRight.svg";
import Img_task from "../../assets/Img_task.png";
import option from "../../assets/option.svg";
import iconplus from "../../assets/icon-plus.png";
import imgAssigned from "../../assets/Assigned.png";
import Upload from "../../assets/Upload.png";
import Dir from "../../assets/dir.svg";
// import BottomBar from "../../components/bottom-bar";
import { useLocation } from "react-router-dom";
import { getUser } from "../../hooks/user";
import { supabase } from "../../helpers/supabase";
import { subscribeToChanges, unsubscribeFromChannel } from "../../helpers/subscriptions";
import { Task } from "../../api/types";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { api, handleTaskDelete, updateTaskOrder } from "../../helpers/api";
import { Editor } from "../../components/tiptap/Editor";
import { generateProfileColor } from "../../helpers/profileColors";
import { ProfileImage } from "../../components/profile/profileImage";
import { KanbanColumn } from "./kanban_columns";
import { ActionsList } from "./kanban_actions";
import TaskUpdateModel from "../../components/modal/TaskUpdateModal";
import { CalculateTimeUntil } from "../../helpers/common";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#18181b',
    color: '#FFFFFF',
    borderRadius: '8px',
    border: '1px solid #333333',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    minWidth: '180px',
  },
  '& .MuiDivider-root': {
    borderColor: '#333333',
    margin: '8px 0',
  },
  '& .MuiMenuItem-root': {
    padding: '8px 16px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
    },
    '&.Mui-disabled': {
      color: '#666666',
      opacity: 1,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
    color: '#FFFFFF',
  },
}));

const KanBanDesk = () => {
  const location = useLocation();
  const { user, projects, projectsLoading } = getUser();
  const projectSlug = location.pathname.split("/")[1];
  const project_id = projects?.find(
    (project: any) => project.slug === projectSlug
  )?.id;

  /* ======================== */
  /* ==== Handle States ===== */
  /* ======================== */
  const [taskStatuses, setTaskStatuses] = useState<any>(null);
  const [tasks, setTasks] = useState<any>([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(2);

  const [createColumnModal, setCreateColumnModal] = useState(false);
  const [columnName, setColumnName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [currentTask, setCurrentTask] = useState<Task>();
  const [taskId, setTaskId] = useState<any>("");
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskStatusId, setTaskStatusId] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskAssignees, setTaskAssignees] = useState<any>([]);
  const [projectMembers, setProjectMembers] = useState<any[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task>();

  const [showColumnInput, setShowColumnInput] = useState(false);

  const [collapsedColumns, setCollapsedColumns] = useState<number[]>([]);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    taskId: number | null;
  } | null>(null);

  const [connectionStatus, setConnectionStatus] = useState<'CONNECTED' | 'CONNECTING' | 'DISCONNECTED'>('CONNECTING');

  /* ======================== */
  /* ==== Use Effects ======= */
  /* ======================== */
  // Add members to project
  useEffect(() => {
    const fetchMembers = async () => {
      const members: any[] = [];

      // add members to project
      await projects
        ?.find((project: any) => project.slug === projectSlug)
        ?.project_members?.forEach((member: any) => {
          members.push({
            id: member.user_id.id,
            name: member.user_id.full_name,
            full_name: member.user_id.full_name,
            profile_color: member.user_id.profile_color,
            assignee_type: "user",
          });
        });

      // add teams to project
      await projects
        ?.find((project: any) => project.slug === projectSlug)
        ?.teams?.forEach((team: any) => {
          members.push({
            id: team.id,
            name: team.name,
            full_name: team.name,
            profile_color: "#000000",
            assignee_type: "team",
          });
        });

      // add owner to members
      members.push({
        id: user.id,
        name: user.full_name,
        full_name: user.full_name,
        profile_color: user.profile_color,
        assignee_type: "user",
      });

      // Set State
      setProjectMembers(members);
    };

    if (projectSlug != null && projectSlug != undefined && !projectsLoading) {
      fetchMembers();
    }
  }, [projects]);

  // Refs
  const tasksRef = useRef(tasks);
  const projectMembersRef = useRef(projectMembers);
  const taskStatusesRef = useRef(taskStatuses);

  useEffect(() => {
    tasksRef.current = tasks;
  }, [tasks]);

  useEffect(() => {
    projectMembersRef.current = projectMembers;
  }, [projectMembers]);

  useEffect(() => {
    taskStatusesRef.current = taskStatuses;
  }, [taskStatuses]);

  // Fetch Task Statuses
  useEffect(() => {
    const getTaskStatuses = async () => {
      // Fetch task statuses and user preferences in parallel
      const [statusesResponse, preferencesResponse] = await Promise.all([
        supabase
          .from("project_task_status")
          .select(`*`)
          .eq("project_id", project_id),
        supabase
          .from("user_column_preferences")
          .select(`*`)
          .eq("user_id", user.id),
      ]);

      if (statusesResponse.error) {
        console.error("Error fetching task statuses: ", statusesResponse.error);
      }
      if (preferencesResponse.error) {
        console.error(
          "Error fetching user preferences: ",
          preferencesResponse.error
        );
      }

      if (statusesResponse.data) {
        setTaskStatuses(statusesResponse.data);

        // Initialize collapsed columns from user preferences
        if (preferencesResponse.data) {
          const collapsedStatuses = preferencesResponse.data
            .filter((pref) => pref.is_collapsed)
            .map((pref) => pref.status_id);
          setCollapsedColumns(collapsedStatuses);
        }

        setTasksLoading(false);
      }
    };

    if (
      projectSlug != null &&
      projectSlug != undefined &&
      !projectsLoading &&
      user
    )
      getTaskStatuses();
  }, [projectSlug, !projectsLoading, user]);

  // Fetch Tasks
  useEffect(() => {
    const getTasks = async () => {
      const { data: data, error } = await supabase
        .from("project_tasks")
        .select(
          `*, project_task_assignees(*, assignee_id(*), team_id(*), user_id(*))`
        )
        .eq("project_id", project_id);
      if (error) {
        console.error("Error fetching user data: ", error);
      }
      if (data) {
        setTasks(data);
      }
      return;
    };

    if (projectSlug != null && projectSlug != undefined && !projectsLoading)
      getTasks();
  }, [projectSlug, !projectsLoading]);

  /* ======================== */
  /* ==== Handle Inputs ===== */
  /* ======================== */
  const handleInputChanges = (e: any) => {
    switch (e.target.id) {
      case "taskName":
        setTaskName(e.target.value);
        break;
      case "taskDescription":
        setTaskDescription(e.target.value);
        break;
      case "columnName":
        setColumnName(e.target.value);
        break;
    }
  };

  /* ======================== */
  /* ==== Task Update ===== */
  /* ======================== */
  const handleTaskUpdate = async () => {
    const { priority } = tasksRef.current.find(
      (task: any) => task.task_id === taskId
    );
    if (taskName === "") {
      alert("Please fill all fields");
      return;
    } else if (
      taskName === currentTask?.name &&
      taskDescription === currentTask?.content &&
      taskStatusId === currentTask?.status &&
      /* due_date === (currentTask?.due_date ? new Date(currentTask.due_date).toDateString() : "") && */
      priority === currentTask?.priority
    ) {
      closeModal();
      return;
    }
    closeModal();

    /* const { error } = await supabase
      .from("project_tasks")
      .update({
        name: taskName,
        content: taskDescription,
        status: taskStatusId,
        priority: priority,
      })
      .eq("task_id", taskId);

    if (error) alert("Error Updating Task");
    if (!error) closeModal(); */
  };

  /* ======================== */
  /* ==== Column Creation === */
  /* ======================== */
  const handleColumnCreation = async () => {
    if (columnName === "") {
      alert("Please fill all fields");
      return;
    }

    const { data, error } = await supabase.from("project_task_status").insert([
      {
        status_name: columnName,
        project_id: project_id,
        order: taskStatuses?.length + 1,
      },
    ]);
    if (error) {
      console.error("Error fetching user data: ", error);
    }
    if (data) {
      return;
    }
    closeModal();
  };

  /* ======================== */
  /* ==== Handle Modals ===== */
  /* ======================== */
  const openModal = (status: string) => {
    setIsModalOpen(true);
    setTaskStatusId(status);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCreateColumnModal(false);
    setIsEditOpen(false);
    setTaskId("");
    setTaskName("");
    setTaskDescription("");
    setTaskDueDate("");
    setTaskStatusId("");
    setColumnName("");
    setTaskAssignees([]);
  };

  const editModal = (task: Task) => {
    setIsEditOpen(true);
    setSelectedTask(task);
    setTaskId(task.task_id);
    setTaskName(task.name);
    setTaskDescription(task.content);
    setTaskStatusId(task.status);
    setTaskDueDate(new Date(task.due_date).toDateString());
    setTaskAssignees(task.project_task_assignees);
  };

  /* ======================== */
  /* ==== Realtime Events === */
  /* ======================== */
  // handle task changes
  const handleTaskChanges = (payload: any) => {
    switch (payload.eventType) {
      case "INSERT":
        if (payload.new.project_id === project_id) {
          setTasks([
            ...tasksRef.current,
            {
              ...payload.new,
              project_task_assignees: [],
            },
          ]);
        }
        break;
      case "UPDATE":
        if (payload.new.project_id === project_id) {
          setTasks((currentTasks: any) =>
            currentTasks.map((task: any) =>
              task.task_id === payload.new.task_id
                ? {
                    ...payload.new,
                    project_task_assignees: task.project_task_assignees,
                  }
                : task
            )
          );
        }
        break;
      case "DELETE":
        setTasks(
          tasksRef.current.filter(
            (task: any) => task.task_id !== payload.old.task_id
          )
        );
        break;
    }
  };

  // handle task status changes
  const handleTaskStatusChanges = (payload: any) => {
    switch (payload.eventType) {
      case "INSERT":
        if (payload.new.project_id === project_id) {
          setTaskStatuses([...taskStatusesRef.current, payload.new]);
        }
        break;
      case "UPDATE":
        if (payload.new.project_id === project_id) {
          setTaskStatuses((prev: any) =>
            prev.map((status: any) =>
              status.status_id === payload.new.status_id
                ? {
                    ...payload.new,
                  }
                : status
            )
          );
        }
        break;
      case "DELETE":
        setTaskStatuses(
          taskStatusesRef.current.filter(
            (taskStatus: any) => taskStatus.status_id !== payload.old.status_id
          )
        );
        // Remove from collapsed columns if deleted
        setCollapsedColumns((prev) =>
          prev.filter((id) => id !== payload.old.status_id)
        );
        // Clean up user preferences for deleted column
        supabase
          .from("user_column_preferences")
          .delete()
          .eq("status_id", payload.old.status_id)
          .eq("user_id", user.id);
        break;
    }
  };

  // handle assignee changes
  const handleAssigneeChanges = (payload: any) => {
    // get assignee for new and old
    switch (payload.eventType) {
      case "INSERT":
        const assignee = projectMembersRef.current.find(
          (member: any) =>
            member.id === payload.newuser_id || payload.new.assignee_id
        );
        setTasks(
          tasksRef.current.map((task: any) =>
            task.task_id === payload.new.task_id
              ? {
                  ...task,
                  project_task_assignees: [
                    ...task.project_task_assignees,
                    {
                      assignee_id: { ...assignee?.id },
                      assignee_type: payload.new.assignee_type,
                      assignment_id: payload.new.assignment_id,
                      task_id: payload.new.task_id,
                      team_id: payload.new.team_id,
                      user_id: payload.new.user_id,
                    },
                  ],
                }
              : task
          )
        );
        break;
      case "DELETE":
        setTasks(
          tasksRef.current.map((task: any) =>
            task.project_task_assignees.find(
              (t: any) => t.assignment_id !== payload.old.assignment_id
            )
              ? {
                  ...task,
                  project_task_assignees: task.project_task_assignees.filter(
                    (t: any) => t.assignment_id !== payload.old.assignment_id
                  ),
                }
              : task
          )
        );
        break;
    }
  };

  /* ======================== */
  /* ==== Drag and Drop ===== */
  /* ======================== */
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    // Make sure we have a valid destination/column
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Item being dragged
    const task = tasksRef.current.find(
      (task: any) => task.task_id === Number(draggableId)
    );

    // Update Task
    const updatedTask = {
      ...task,
      status: Number(destination.droppableId),
      order: Number(destination.index),
    };

    // Get tasks to update
    let tasksToUpdate = tasksRef.current
      ?.filter((task: any) => task.status === Number(destination.droppableId))
      ?.sort((a: any, b: any) => a?.order - b?.order);

    // Check if task is already in tasksToUpdate list
    if (
      tasksToUpdate?.some((task: any) => task.task_id === updatedTask.task_id)
    ) {
      tasksToUpdate = tasksToUpdate.filter(
        (task: any) => task.task_id !== updatedTask.task_id
      );
    }

    // Insert updatedTask at the new position
    tasksToUpdate.splice(destination.index, 0, updatedTask);

    // Update the order of tasks in tasksToUpdate list
    tasksToUpdate.forEach((task: any, index: number) => {
      task.order = index + 1;
    });

    // Sort tasksToUpdate
    tasksToUpdate.sort((a: any, b: any) => a?.order - b?.order);

    if (task.order !== undefined && task.order !== null) {
      setTasks((currentTasks: any) =>
        currentTasks.map((task: any) =>
          task.task_id === Number(draggableId)
            ? {
                ...task,
                status: Number(destination.droppableId),
                order: Number(destination.index),
              }
            : task
        )
      );

      // Save the new order to the database
      const updateTask = async () => {
        await updateTaskOrder({
          task_id: Number(draggableId),
          order: Number(destination.index) + 1,
          status_id: Number(destination.droppableId),
        });
        /* await api.bulkUpdateTasks({
          updatedTasks: tasksToUpdate,
        }); */
      };
      updateTask();
    }
  };

  const ref = useRef<any>(null);
  const { events } = useDraggable(ref);

  /* ======================== */
  /* ==== Task Delete ====== */
  /* ======================== */
  const deleteAlert = async ({ task_id }: { task_id: Number }) => {
    // Confirm delete
    if (window.confirm("Are you sure you want to delete this task?")) {
      // Remove task from tasks
      setTasks(
        tasksRef.current.filter((task: any) => task.task_id !== task_id)
      );

      // Delete task from database
      await handleTaskDelete({ task_id });
    }
  };

  /* ======================== */
  /* ==== Handle Updates ====== */
  /* ======================== */
  // subscribe to task changes in realtime
  useEffect(() => {
    if (!projectsLoading && !tasksLoading) {
      const channel = subscribeToChanges({
        channelName: "project_tasks",
        eventHandler: handleTaskChanges,
        onConnectionChange: setConnectionStatus
      });

      // Cleanup subscription on unmount
      return () => {
        if (channel) {
          unsubscribeFromChannel(channel);
        }
      };
    }
  }, [projectsLoading, tasksLoading]);

  // subscribe to task status changes in realtime
  useEffect(() => {
    if (!projectsLoading) {
      const channel = subscribeToChanges({
        channelName: "project_task_status", 
        eventHandler: handleTaskStatusChanges,
        onConnectionChange: setConnectionStatus
      });

      return () => {
        if (channel) {
          unsubscribeFromChannel(channel);
        }
      };
    }
  }, [projectsLoading]);

  // subscribe to changes on assignees
  useEffect(() => {
    if (!projectsLoading) {
      const channel = subscribeToChanges({
        channelName: "project_task_assignees",
        eventHandler: handleAssigneeChanges,
        onConnectionChange: setConnectionStatus
      });

      return () => {
        if (channel) {
          unsubscribeFromChannel(channel);
        }
      };
    }
  }, [projectsLoading]);

  const handleCreateColumn = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter" && columnName.trim()) {
      const { data, error } = await supabase
        .from("project_task_status")
        .insert([
          {
            status_name: columnName,
            project_id: project_id,
            order: taskStatuses?.length + 1,
          },
        ]);

      if (error) {
        console.error("Error creating column: ", error);
      }

      setColumnName("");
      setShowColumnInput(false);
    }
  };

  // Update the animation variants
  const inputVariants = {
    hidden: {
      opacity: 0,
      y: -10,
      scale: 0.95,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 25,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.15,
      },
    },
  };

  // Simplify the plus button variants
  const plusButtonVariants = {
    initial: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const toggleColumnCollapse = async (statusId: number) => {
    // Update local state immediately for responsive UI
    setCollapsedColumns((prev) =>
      prev.includes(statusId)
        ? prev.filter((id) => id !== statusId)
        : [...prev, statusId]
    );

    // Update the database
    const { error } = await supabase.from("user_column_preferences").upsert(
      {
        user_id: user.id,
        status_id: statusId,
        is_collapsed: !collapsedColumns.includes(statusId),
      },
      {
        onConflict: "user_id,status_id",
      }
    );

    if (error) {
      console.error("Error updating column collapse state:", error);
      // Revert local state if database update fails
      setCollapsedColumns((prev) =>
        prev.includes(statusId) ? prev : prev.filter((id) => id !== statusId)
      );
    }
  };

  // Add these animation variants near your other variants
  const taskListVariants = {
    hidden: {
      opacity: 0,
      width: 0,
    },
    visible: {
      opacity: 1,
      width: "100%",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
    exit: {
      opacity: 0,
      width: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  const taskItemVariants = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
    exit: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.2,
      },
    },
  };

  const taskContentVariants = {
    hidden: { 
      opacity: 0
    },
    visible: { 
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.2
      }
    },
    exit: {
      opacity: 0
    }
  };

  const handleContextMenu = (event: React.MouseEvent, taskId: number) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      taskId,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handlePriorityChange = async (priority: number) => {
    if (!contextMenu?.taskId) return;

    const task = tasks.find((t: any) => t.task_id === contextMenu.taskId);
    if (!task) return;

    // Update local state
    setTasks((currentTasks: any) =>
      currentTasks.map((t: any) =>
        t.task_id === contextMenu.taskId
          ? { ...t, priority }
          : t
      )
    );

    // Update in database
    const { error } = await supabase
      .from('project_tasks')
      .update({ priority })
      .eq('task_id', contextMenu.taskId);

    if (error) {
      console.error('Error updating priority:', error);
      // Revert on error
      setTasks((currentTasks: any) =>
        currentTasks.map((t: any) =>
          t.task_id === contextMenu.taskId
            ? { ...t, priority: task.priority }
            : t
        )
      );
    }

    handleContextMenuClose();
  };

  const handleMoveToColumn = async (statusId: number) => {
    if (!contextMenu?.taskId) return;

    const task = tasks.find((t: any) => t.task_id === contextMenu.taskId);
    if (!task) return;

    // Get tasks in target column and shift their orders down by 1
    const tasksInTargetColumn = tasks.filter((t: any) => t.status === statusId);
    
    // Set the moved task to order 1 (top of column)
    setTasks((currentTasks: any) =>
      currentTasks.map((t: any) => {
        if (t.task_id === contextMenu.taskId) {
          return { ...t, status: statusId, order: 1 };
        }
        // Increment order of existing tasks in the target column
        if (t.status === statusId) {
          return { ...t, order: t.order + 1 };
        }
        return t;
      })
    );

    // Update task position to top of new column
    await updateTaskOrder({
      task_id: contextMenu.taskId,
      status_id: statusId,
      order: 1,
    });

    handleContextMenuClose();
  };

  return (
    <>
      <div
        className="project-content relative  w-[100%] sm:w-[100%] mx-auto"
        style={{height: "inherit"}}
        {...events}
        ref={ref}
      >
        <div
          className="grid mb-2 grid-cols-1 absolute left-0 right-0 h-[calc(100%-6rem)] lg:h-[calc(100%-4rem)]"
          style={{height: "inherit"}}>
          {activeTab === 2 && (
            <div className="flex w-[100%] scrolling overflow-x-scroll">
              {/* ======================== */
              /* ==== Tasks Statuses ==== */
              /* ======================== */}
              <DragDropContext onDragEnd={onDragEnd}>
                {taskStatuses
                  ?.sort((a: any, b: any) => a.order - b.order)
                  ?.map((status: any, index: number) => {
                    const statusTasks = tasks?.filter(
                      (task: any) => task.status === status.status_id
                    );
                    const isCollapsed = collapsedColumns.includes(
                      status.status_id
                    );

                    return (
                      <div
                        key={status.status_id}
                        className={`h-auto overflow-scroll flex-shrink-0 transition-all duration-300 dark:bg-mainBg m-1 px-2 rounded-sm border-t-[3px] border-borderColor dark:border-darkBorderColor ${
                          isCollapsed ? "basis-[60px]" : "basis-[330px]"
                        }`}
                        style={{ borderColor: status.color }}
                      >
                        <div className="text-textColor dark:text-darkTextActiveColor cursor-pointer py-4 rounded-lg sticky top-0 bg-white dark:bg-mainBg z-[1]">
                          <div
                            className={`flex font-bold text-left items-start justify-between px-4 ${
                              isCollapsed ? "flex-col min-h-[200px]" : ""
                            }`}
                          >
                            <div
                              className={`flex items-start gap-2 ${
                                isCollapsed
                                  ? "flex-col w-full items-center"
                                  : "w-full"
                              }`}
                            >
                              <button
                                onClick={() =>
                                  toggleColumnCollapse(status.status_id)
                                }
                                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-800 rounded shrink-0"
                              >
                                <svg
                                  className={`w-4 h-4 transition-transform`}
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={
                                      isCollapsed
                                        ? "M9 5l7 7-7 7"
                                        : "M15 19l-7-7 7-7"
                                    }
                                  />
                                </svg>
                              </button>
                              {isCollapsed ? (
                                <div
                                  className={styles["writing-mode-vertical"]}
                                >
                                  <p>
                                    <span>{status.status_name}</span>{" "}
                                    <span className="text-xs text-gray-500">
                                      ({statusTasks?.length || 0})
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <KanbanColumn
                                  status={status}
                                  statusTasks={statusTasks}
                                  taskStatusesRef={taskStatusesRef}
                                  setTaskStatuses={setTaskStatuses}
                                  openModal={(status: string) =>
                                    openModal(status)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        {!isCollapsed && (
                          <Droppable droppableId={status.status_id.toString()}>
                            {(provided) => (
                              <AnimatePresence mode="wait">
                                <motion.ul
                                  ref={provided.innerRef}
                                  className="rounded-lg px-1 mt-5 w-[100%]"
                                  variants={taskListVariants}
                                  initial="hidden"
                                  animate="visible"
                                  exit="exit"
                                  {...provided.droppableProps}
                                >
                                  {statusTasks
                                    ?.sort(
                                      (a: any, b: any) => a.order - b.order
                                    )
                                    .filter(
                                      (task: any) =>
                                        task.status === status.status_id
                                    )
                                    ?.map((task: any, index: number) => {
                                      return (
                                        <Draggable
                                          key={task.task_id}
                                          draggableId={task.task_id.toString()}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <motion.li
                                              key={task.task_id}
                                              ref={provided.innerRef}
                                              variants={taskItemVariants}
                                              initial="hidden"
                                              animate="visible"
                                              exit="exit"
                                            >
                                              <div
                                                className="group relative bg-white dark:bg-darkBorderColor/20 mb-3 rounded-lg py-3 px-4 hover:shadow-md hover:cursor-pointer border border-[#E5E5E5] dark:border-[#333333] hover:border-[#CCCCCC] dark:hover:border-[#444444] transition-all duration-200"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onContextMenu={(e) => handleContextMenu(e, task.task_id)}
                                              >
                                                <div
                                                  id={status.status_id}
                                                  onClick={() =>
                                                    editModal(task)
                                                  }
                                                  className="pmtool_task h-full w-full bg-transparent absolute left-0"
                                                  datatype="task"
                                                />
                                                <motion.div 
                                                  className="space-y-3"
                                                  variants={taskContentVariants}
                                                >
                                                  {/* Task Header */}
                                                  <div className="flex items-center justify-between">
                                                    <span className="text-[#888888] text-xs font-medium">
                                                      #{task.project_task_id}
                                                    </span>
                                                    <div className="flex items-center gap-2">
                                                      {/* Priority Icon */}
                                                      <img
                                                        src={`/assets/svgs/priority-${task.priority}.svg`}
                                                        alt={task.priority}
                                                        className="w-4 h-4"
                                                      />
                                                      {/* Delete Button */}
                                                      <button
                                                        className="opacity-0 group-hover:opacity-50 hover:opacity-100 transition-opacity"
                                                        onClick={() => deleteAlert({ task_id: task.task_id })}
                                                      >
                                                        <img
                                                          src={trash}
                                                          alt="delete task"
                                                          className="w-4 h-4"
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>

                                                  {/* Task Title */}
                                                  <h2 className="text-[#1C1C1C] dark:text-white text-sm font-medium line-clamp-2">
                                                    {task.name}
                                                  </h2>

                                                  {/* Task Footer */}
                                                  <div className="flex items-center justify-between pt-1">
                                                    {/* Due Date */}
                                                    {task.enable_due_date && task.due_date && (
                                                      <div className="flex items-center gap-1.5 text-[#666666] dark:text-[#888888]">
                                                        <img
                                                          src={`/assets/svgs/calendar.svg`}
                                                          alt="due date"
                                                          className="w-4 h-4"
                                                        />
                                                        <span className="text-xs">
                                                          <CalculateTimeUntil date={task?.due_date} />
                                                        </span>
                                                      </div>
                                                    )}

                                                    {/* Assignees */}
                                                    <div className="flex -space-x-2 ml-auto">
                                                      {task.project_task_assignees?.map((assignee: any) => (
                                                        <ProfileImage
                                                          key={assignee.assignment_id}
                                                          user={
                                                            assignee.assignee_type === "team"
                                                              ? assignee.team_id
                                                              : assignee.user_id ?? assignee.assignee_id
                                                          }
                                                          small={true}
                                                          pill={true}
                                                        />
                                                      ))}
                                                    </div>
                                                  </div>
                                                </motion.div>
                                              </div>
                                            </motion.li>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  {provided.placeholder}
                                </motion.ul>
                              </AnimatePresence>
                            )}
                          </Droppable>
                        )}
                      </div>
                    );
                  })}
              </DragDropContext>

              <div className="h-auto overflow-scroll flex-shrink-0 basis-[330px] dark:bg-mainBg m-1 px-2 rounded-sm border-t-[3px] border-borderColor dark:border-darkBorderColor">
                <AnimatePresence mode="wait">
                  {showColumnInput ? (
                    <motion.div
                      key="input"
                      variants={inputVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="text-textColor dark:text-darkTextActiveColor py-4 rounded-lg sticky top-0 bg-white dark:bg-mainBg"
                    >
                      <div className="flex font-bold text-left items-start justify-between px-4">
                        <div className="flex items-center gap-2 w-full">
                          <input
                            type="text"
                            value={columnName}
                            onChange={(e) => setColumnName(e.target.value)}
                            onKeyDown={handleCreateColumn}
                            onBlur={() => {
                              setShowColumnInput(false);
                              setColumnName("");
                            }}
                            placeholder="Enter column name..."
                            autoFocus
                            className="w-full px-3 py-2 rounded-lg bg-transparent border border-borderColor dark:border-darkBorderColor focus:outline-none focus:border-mainColor dark:focus:border-mainColor transition-colors duration-200"
                          />
                          <button
                            onClick={() => {
                              if (columnName.trim()) {
                                handleCreateColumn({
                                  key: "Enter",
                                } as React.KeyboardEvent<HTMLInputElement>);
                              } else {
                                setShowColumnInput(false);
                              }
                            }}
                            className="shrink-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded transition-colors duration-200"
                          >
                            {columnName.trim() ? (
                              <svg
                                className="w-5 h-5 text-mainColor"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="w-5 h-5 text-gray-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="button"
                      variants={plusButtonVariants}
                      initial="initial"
                      exit="exit"
                      onClick={() => setShowColumnInput(true)}
                      className="text-textColor dark:text-darkTextActiveColor cursor-pointer py-4 rounded-lg sticky top-0 bg-white dark:bg-mainBg"
                    >
                      <div className="flex gap-2 font-bold items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-800 py-2 px-4 rounded-lg transition-colors duration-200">
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                        <span className="text-sm">Add Column</span>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <BottomBar /> */}

      {/* ======================== */
      /* ====== Modals ========== */
      /* ======================== */}
      {/* Edit Task Modal */}
      <TaskUpdateModel
        task={selectedTask}
        tasks={tasks}
        setTasks={setTasks}
        isOpen={isEditOpen}
        onClose={() => handleTaskUpdate()}
      />

      {/* Create Task Modal */}
      <TaskModel
        isOpen={isModalOpen}
        onClose={closeModal}
        defaultStatus={Number(taskStatusId)}
      />

      {/* Create Column Modal */}
      {/* <TaskModel isOpen={createColumnModal} onClose={() => closeModal()}>
        <div className=" pt-5 pb-2 px-5 ">
          <div className="grid grid-cols-1 py-4 ">
            <input
              className="text-[24px] text-white font-bold border-none outline-none focus:outline-none bg-transparent"
              type={""}
              placeholder={"Column Name"}
              onChange={(e) => handleInputChanges(e)}
              value={columnName}
              id="columnName"
            />
          </div>
          <div className="border my-2 border-[#CFDBD5]"></div>
        </div>
        <div className="bg-transparent px-4 items-center  rounded-b-[20px] py-2">
          <button
            onClick={() => handleColumnCreation()}
            className="bg-mainColor rounded-md p-2 text-white font-bold text-sm w-full"
          >
            Add Column
          </button>
        </div>
      </TaskModel> */}

      <StyledMenu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem disabled sx={{ 
          fontSize: '12px !important', 
          color: '#888888 !important',
          paddingY: '4px !important',
        }}>
          <ListItemText primary="Priority" />
        </MenuItem>
        <MenuItem onClick={() => handlePriorityChange(1)}>
          <ListItemIcon>
            <img src="/assets/svgs/priority-1.svg" alt="Urgent" className="w-4 h-4" />
          </ListItemIcon>
          <ListItemText 
            primary="Urgent" 
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '14px',
              } 
            }} 
          />
        </MenuItem>
        <MenuItem onClick={() => handlePriorityChange(2)}>
          <ListItemIcon>
            <img src="/assets/svgs/priority-2.svg" alt="High" className="w-4 h-4" />
          </ListItemIcon>
          <ListItemText 
            primary="High"
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '14px',
              } 
            }}
          />
        </MenuItem>
        <MenuItem onClick={() => handlePriorityChange(3)}>
          <ListItemIcon>
            <img src="/assets/svgs/priority-3.svg" alt="Medium" className="w-4 h-4" />
          </ListItemIcon>
          <ListItemText 
            primary="Medium"
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '14px',
              } 
            }}
          />
        </MenuItem>
        <MenuItem onClick={() => handlePriorityChange(4)}>
          <ListItemIcon>
            <img src="/assets/svgs/priority-4.svg" alt="Low" className="w-4 h-4" />
          </ListItemIcon>
          <ListItemText 
            primary="Low"
            sx={{ 
              '& .MuiTypography-root': { 
                fontSize: '14px',
              } 
            }}
          />
        </MenuItem>
        <Divider />
        <MenuItem disabled sx={{ 
          fontSize: '12px !important', 
          color: '#888888 !important',
          paddingY: '4px !important',
        }}>
          <ListItemText primary="Move to" />
        </MenuItem>
        {taskStatuses?.map((status: any) => (
          <MenuItem
            key={status.status_id}
            onClick={() => handleMoveToColumn(status.status_id)}
            disabled={contextMenu?.taskId ? 
              tasks.find((t: any) => t.task_id === contextMenu.taskId)?.status === status.status_id 
              : false
            }
          >
            <ListItemText 
              primary={status.status_name}
              sx={{ 
                '& .MuiTypography-root': { 
                  fontSize: '14px',
                } 
              }}
            />
          </MenuItem>
        ))}
      </StyledMenu>

      <Dialog 
        open={connectionStatus !== 'CONNECTED'} 
        PaperProps={{
          style: {
            backgroundColor: '#18181b',
            color: '#FFFFFF',
            borderRadius: '8px',
            border: '1px solid #333333',
          }
        }}
      >
        <DialogContent>
          <div className="flex flex-col items-center gap-4 p-4">
            {connectionStatus === 'CONNECTING' && (
              <>
                <CircularProgress size={24} />
                <div className="text-center">
                  <h3 className="text-lg font-medium mb-2">Reconnecting...</h3>
                  <p className="text-sm text-gray-400">
                    Attempting to restore real-time updates
                  </p>
                </div>
              </>
            )}
            {connectionStatus === 'DISCONNECTED' && (
              <Alert 
                severity="error" 
                sx={{
                  backgroundColor: 'transparent',
                  color: '#ef4444',
                  border: '1px solid #ef4444',
                  '& .MuiAlert-icon': {
                    color: '#ef4444'
                  }
                }}
              >
                Connection lost. Attempting to reconnect...
              </Alert>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default KanBanDesk;
