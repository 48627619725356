import { supabase } from "./supabase"

const API_URL = process.env.REACT_APP_API_URL

if (!API_URL) {
  throw new Error('Missing API URL environment variable')
}

export const api = {
  get: async (endpoint: string) => {
    const response = await fetch(`${API_URL}${endpoint}`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`)
    }
    return response.json()
  },
  
  post: async (endpoint: string, data: any) => {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`)
    }
    return response.json()
  },
  // Add other methods as needed
}








/* ======================== */
// Fetch Project Members
/* ======================== */
export const fetchProjectMembers = async () => {
  const workspace = sessionStorage.getItem("workspace");
  const { data, error } = await supabase.rpc('get_team_members', {
    p_workspace: workspace
  });
  if (error) {
    console.error("Error fetching Members:", error);
    return [];
  }
  return data;
};

/* ======================== */
// Fetch Logs
/* ======================== */
export const fetchLogs = async () => {
  const workspace = sessionStorage.getItem("workspace");
  const { data, error } = await supabase.rpc('get_project_audit_log', {
    p_workspace: workspace
});
  if (error) {
    console.error("Error fetching logs:", error);
    return [];
  }
  return data;
};

/* ======================== */
// Fetch Tasks Statuses
/* ======================== */
export const fetchTasksStatuses = async () => {
  const workspace = sessionStorage.getItem("workspace");
  const { data, error } = await supabase.rpc('get_task_status_counts', {
    p_workspace: workspace
  });
  if (error) {
    console.error("Error fetching tasks statuses:", error);
    return [];
  }
  return data;
};

/* ======================== */
// Start Task APIs
/* ======================== */
const bulkUpdateTasks = async ({
  updatedTasks,
}: {
  updatedTasks: {
    id: number;
    task_id: number;
    order: number;
    name: string;
    content: string;
    due_date: string;
    project_id: string;
    created_at: string;
    status: number;
    created_by: string;
  }[];
}) => {
  // Batch update order in Supabase
  const updates = updatedTasks.map((task, index) => ({
    task_id: task.task_id,
    name: task.name,
    content: task.content,
    due_date: task.due_date,
    project_id: task.project_id,
    status: task.status,
    created_at: task.created_at,
    created_by: task.created_by,
    order: index + 1,
  }));

  const { error } = await supabase.from("project_tasks").upsert(updates);
  if (error) {
    console.error("Error updating task order:", error);
    return { error: "Error Updating Task Order", success: false };
  }
  return { error: "", success: true };
};

/* ======================== */
// Update Single Task order
/* ======================== */
export const updateTaskOrder = async ({
  task_id,
  order,
  status_id,
}: {
  task_id: number;
  order: number;
  status_id: number;
}) => {
  const { error } = await supabase
    .from("project_tasks")
    .update({ order: order, status: status_id })
    .eq("task_id", task_id);

  if (error) {
    console.error("Error Updating Task Order: ", error);
    return { error: "Error Updating Task Order", success: false };
  }

  return { error: "", success: true };
};

/* ======================== */
// Update Task Status
/* ======================== */
export const updateColumnOrder = async ({
  column_id,
  order,
}: {
  column_id: number;
  order: number;
}) => {
  const { error } = await supabase
    .from("project_task_status")
    .update({ order: order })
    .eq("status_id", column_id);

  if (error) {
    console.error("Error Updating Task Status: ", error);
    return { error: "Error Updating Task Status", success: false };
  }

  return { error: "", success: true };
};

export const updateTaskStatuses = async ({ statuses }: { statuses: any }) => {
  const updates = statuses.map((task: any, index: any) => ({
    status_name: task.status_name,
    status_id: task.status_id,
    order: task.order,
    color: task.color,
  }));

  const { error } = await supabase.from("project_task_status").upsert(updates);

  if (error) {
    console.error("Error Updating Task Status: ", error);
    return { error: "Error Updating Task Status", success: false };
  }

  return { error: "", success: true };
};

// Task: Update Task
const updateTask = async ({
  task_id,
  task,
}: {
  task_id: number;
  task: {
    task_id: number;
    order: number;
    name: string;
    content: string;
    due_date: string;
    project_id: string;
    created_at: string;
    status: number;
    created_by: string;
  };
}) => {
  const { error } = await supabase
    .from("project_tasks")
    .update({
      name: task.name,
      content: task.content,
      due_date: task.due_date,
      status: task.status,
      order: task.order,
    })
    .eq("task_id", task_id);

  if (error) {
    console.error("Error Updating Task: ", error);
    return { error: "Error Updating Task", success: false };
  }

  return { error: "", success: true };
};

// Task: Add Member to Task
const addMemberToTask = async ({
  assignee_id,
  task_id,
  assignee_type,
  team_id,
  user_id,
}: {
  assignee_id: string;
  task_id: string;
  assignee_type: string;
  team_id: string;
  user_id: string;
}) => {

  console.log({
    assignee_id: assignee_id,
    task_id: task_id,
    assignee_type: assignee_type,
    user_id: assignee_id,
  })
  // check if member is already in task
  const { error } = await supabase.from("project_task_assignees").insert([
    {
      task_id: task_id,
      assignee_type: assignee_type,
      assignee_id: assignee_id,
      user_id: assignee_type === "user" ? user_id : null,
      team_id: assignee_type === "team" ? team_id : null,
    },
  ]);

  if (error) {
    return { error: "Error Adding Member to Task", success: false };
  }

  return { error: "", success: true };
};

// Task: Remove Member from Task
const removeMemberFromTask = async ({
  assignment_id
}: {
  assignment_id: number;
}) => {
  // check if member is already in task
  const { error } = await supabase
    .from("project_task_assignees")
    .delete()
    .eq("assignment_id", assignment_id);

  if (error) {
    return { error: "Error Removing Member to Task", success: false };
  }

  return { error: "", success: true };
};

/* ======================== */
// End Task APIs
/* ======================== */

/* ======================== */
// Handle Members
/* ======================== */
export const addMember = async ({
  member_id,
  project_id,
}: {
  member_id: string;
  project_id: string;
}) => {
  // check if member is already in project
  const { data: member, error: memberError } = await supabase
    .from("project_members")
    .select("*")
    .eq("user_id", member_id)
    .eq("project_id", project_id)
    .select();

  if (memberError) {
    console.error("Error Getting Member: ", memberError);
    return { error: "Error Adding Member to Project", success: false };
  }

  if (member?.length ?? 0 > 0) {
    return { error: "Member Already in Project", success: false };
  }

  // add member to supabase and send email
  const { error } = await supabase.from("project_members").insert([
    {
      project_id: project_id,
      user_id: member_id,
    },
  ]);

  if (error) {
    return { error: "Error Adding Member to Project", success: false };
  }

  return { error: "", success: true, member: member };
};

// Delete Members
export const deleteMember = async ({
  id,
  project_id,
}: {
  id: any;
  project_id: string;
}) => {
  // delete members from project
  const { error } = await supabase.rpc('delete_team_member', {
    p_workspace: project_id,
    p_user_id: id
  });

  if (error) {
    console.error("Error Deleting Members: ", error);
    return { error: error.message, success: false };
  }
  return { error: "", success: true };
};

/* ======================== */
/* ==== Delete Task === */
/* ======================== */
export const handleTaskDelete = async ({ task_id }: { task_id: Number }) => {
  const { data, error } = await supabase
    .from("project_tasks")
    .delete()
    .eq("task_id", task_id);
  if (error) {
    console.error("Error fetching user data: ", error);
  }
  if (data) {
    return;
  }
};

/* ======================== */
// Create Team
/* ======================== */
export const createTeam = async ({ teamName, project_id }: { teamName: string, project_id: string }) => {
  const { data, error } = await supabase.from("teams")
    .insert([{ name: teamName, project_id: project_id }])
    .order("name", { ascending: false })
    .select();

  if (error) {
    console.error("Error fetching user data: ", error);
  }
  if (data) {
    return data;
  }
};

/* ======================== */
// Get Teams
/* ======================== */
export const getTeams = async ({ project_id }: { project_id: string }) => {
  const { data, error } = await supabase.rpc('get_project_teams', {
    p_workspace: project_id
  });

  if (error) {
    console.error("Error fetching Teams: ", error);
  }
  if (data) {
    return data;
  }
};


/* ======================== */
// Add Member to Team
/* ======================== */
export const addMemberToTeam = async ({ project_id, team_id, user_id }: { project_id: string, team_id: string, user_id: string }) => {
  const { data, error } = await supabase.rpc('add_member_to_project_team', {
    p_workspace: project_id,
    p_team_id: team_id,
    p_user_id: user_id
  });

  if (error) {
    console.error("Error fetching Team Members: ", error);
    return { error: error.message, success: false };
  }
  if (data) {
    return data;
  }
};