import { useState } from "react";
import { ProfileModal } from "../modal/ProfileModal";
import { copyToClipboard, truncate } from "../../helpers/common";
import { supabase } from "../../helpers/supabase";
import { ProfileImage } from "./profileImage";
import { useWorkspace } from "../../context/workspace";
import { WorkspaceData } from "../../context/workspace/useContext";
import { useNavigate } from "react-router-dom";

interface ProfileProps {
  user: any;
  team1: any;
  Option: any;
  isCollapsed?: boolean;
}

export function Profile({
  user,
  team1,
  Option,
  isCollapsed,
}: ProfileProps) {
  const navigate = useNavigate();
  const { workspaces } = useWorkspace();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isWorkspacesOpen, setIsWorkspacesOpen] = useState(false);

  const handleProfileID = () => {
    copyToClipboard(user?.id);
    setIsDropdownOpen(false);
  };

  const handleWorkspace = ({ workspace }: { workspace: WorkspaceData }) => {
    if (workspace) {
      sessionStorage.setItem("workspace", workspace.id);
      window.location.href = `/${workspace.slug}/overview`;
    }
  };

  return (
    <>
      <div
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex justify-between absolute left-0 bottom-0 bg-white px-4 xl:px-10 py-4 w-[100%] border-t-[1px] border-borderColor dark:border-darkBorderColor dark:bg-bgDark cursor-pointer"
      >
        <div className="flex justify-center gap-3">
          {user && <ProfileImage user={user} small={true} show_name={false} />}

          <p className="text-textColor dark:text-darkTextColor text-[12px] xl:text-[14px] ml-2 self-center gap-3 font-RobotoFlexRegular font-bold">
            {truncate(user?.full_name, 15) ??
              user?.user_metadata?.first_name ??
              truncate(user.email, 10)}
          </p>
        </div>
      </div>

      {isDropdownOpen && (
        <div
          className="absolute left-0 bottom-12 h-full w-full bg-[#1a1b1fc9] backdrop-blur-sm"
          //onClick={() => setIsDropdownOpen(false)}
        >
          <div className="absolute left-0 bottom-0 bg-white dark:bg-[#0f1011] shadow-lg w-full border-b-[1px] border-borderColor dark:border-darkBorderColor">
            {/* Hide if workspace is open */}
            {!isWorkspacesOpen && (
              <>
                <button
                  onClick={() => setIsProfileModalOpen(true)}
                  className="block w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor hover:bg-gray-200 dark:hover:bg-gray-700 bg-bgDark/40"
                >
                  Profile
                </button>
                <button
                  onClick={() => handleProfileID()}
                  className="block w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor hover:bg-gray-200 dark:hover:bg-gray-700 bg-bgDark/40"
                >
                  Copy Invite ID
                </button>
                <button
                  onClick={() => {
                    supabase.auth.signOut();
                    window.location.reload();
                  }}
                  className="block w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor hover:bg-gray-200 dark:hover:bg-gray-700 bg-bgDark/40"
                >
                  Logout
                </button>
              </>
            )}
            <div className="relative">
              {!isWorkspacesOpen && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsWorkspacesOpen(!isWorkspacesOpen);
                  }}
                  className="border-t border-borderColor dark:border-darkBorderColor block w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor bg-bgDark hover:bg-bgDarkHover"
                >
                  Workspaces &#x25B6;
                </button>
              )}
              {isWorkspacesOpen &&
                workspaces?.map((workspace, index) => {
                  return (
                    <>
                      <div className="border-t border-borderColor dark:border-darkBorderColor"></div>
                      <button
                        key={index}
                        onClick={() =>
                          handleWorkspace({ workspace: workspace })
                        }
                        className={
                          `${workspace.id === sessionStorage.getItem("workspace") ? 'bg-bgDarkHover' : ''} flex justify-between w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor hover:bg-bgDark dark:hover:bg-bgDark text-sm`
                        }
                      >
                        {workspace.name}
                        {workspace.owner_id === user.id ? (
                          <span className="bg-mainColor px-2 py-1 rounded-full text-xs">
                            owner
                          </span>
                        ) : (
                          ""
                        )}
                      </button>
                    </>
                  );
                })}
            </div>

            {/* Show if workspace is open */}
            {isWorkspacesOpen && (
              <>
                <button
                  onClick={() => setIsWorkspacesOpen(!isWorkspacesOpen)}
                  className="border-t border-borderColor dark:border-darkBorderColor block w-full text-left px-4 py-4 text-textColor dark:text-darkTextColor bg-bgDark hover:bg-bgDarkHover"
                >
                  &#x25c0; Back
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <ProfileModal
        user={user}
        setIsProfileModalOpen={setIsProfileModalOpen}
        isProfileModalOpen={isProfileModalOpen}
      />
    </>
  );
}
