import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

export type Workspace = {
  id: string;
  created_at: string;
  name: string;
  created_by: string;
};

export type WorkspaceMember = {
  workspace_id: string;
  user_id: string;
  role: 'admin' | 'member';
  created_at: string;
};
