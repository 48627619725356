import { useEffect, useRef, useState } from "react";
import { supabase } from "../../helpers/supabase";
import { subscribeToChanges } from "../../helpers/subscriptions";
import { getTeams } from "../../helpers/api";
import { Members, UserData } from "./userContext";

const useUserApi = () => {
  const [userInfo, setUserInfo] = useState<any>({});
  const [userLoading, setUserLoading] = useState<boolean>(true);

  const [projectsLoading, setProjectsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [statuses, setStatuses] = useState<any>();
  const projectsRef = useRef(projects); // Create a ref for projects
  const workspace = sessionStorage.getItem("workspace");

  /* ===================== */
  /* ==== Update Refs ==== */
  /* ===================== */
  const getUserInfo = async () => {
    const { data: isLoggedIn, error } = await supabase.auth.getSession();
    if (!isLoggedIn.session) {
      return;
    }

    //const { data: user, error } = await supabase.auth.getUser();
    const { user } = isLoggedIn.session;
    if (error) {
      console.error("Error fetching user data: ", error);
      // redirect home if no user
      // check location.pathname first
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/signup"
      ) {
        window.location.href = "/";
      }
    }
    if (user) {
      const { data, error: userError } = await supabase
        .from("users")
        .select(`profile_color, full_name, user_plans(*, plan_id(*))`)
        .eq(`id`, user?.id);

      const userData = data
        ? {
            ...user,
            ...data[0],
            user_plan: data[0].user_plans[0],
          }
        : user;
      setUserInfo(userData);
      setUserLoading(false);
    }
    return;
  };

  /* ======================= */
  /* ==== Realtime API ==== */
  /* ======================= */
  // Subscribe to real-time changes on projects
  const handleProjectChanges = (payload: any) => {
    switch (payload.eventType) {
      case "DELETE":
        setProjects(
          projectsRef.current.filter(
            (project: any) => project.id !== payload.old.id
          )
        );
        break;
      case "INSERT":
        if (payload.new.owner_id === userInfo.id) {
          setProjects([...projectsRef.current, payload.new]);
        }
        break;
      case "UPDATE":
        // make sure user is owner of project
        if (payload.new.owner_id === userInfo.id) {
          // update project
          setProjects(
            projectsRef.current.map((project: any) => {
              if (project.id === payload.new.id) {
                return payload.new;
              }
              return project;
            })
          );
        }
        break;
    }
  };

  /* ======================= */
  /* ==== Handle Updates ==== */
  /* ======================= */
  // Subscribe to changes on projects
  useEffect(() => {
    subscribeToChanges({
      channelName: "projects",
      eventHandler: handleProjectChanges,
    });
  }, [!projectsLoading]);

  // Get User Info
  useEffect(() => {
    getUserInfo();
  }, [workspace]);

  // Update Refs
  useEffect(() => {
    projectsRef.current = projects;
  }, [projects]);

  // Fetch Teams
  useEffect(() => {
    const fetchTeams = async () => {
      if (!workspace || !userInfo || userLoading) {
        return;
      }

      const { data, error } = await supabase.rpc("get_project_teams", {
        p_workspace: workspace,
      });
      setUserInfo((prev: any) => ({ ...prev, teams: data }));
    };
    fetchTeams();
  }, [workspace, !userLoading]);

  // Fetch Projects
  useEffect(() => {
    const getUserProjects = async () => {
      const { data, error } = await supabase
        .from("projects")
        .select(
          `*, project_task_status(*), project_tasks(*), project_members(user_id(*)), teams(*, team_members(*))`
        )
        .eq(`owner_id`, userInfo.id);
      if (error) {
        console.error("Error fetching project data: ", error);
      }
      return data || [];
    };

    // fetch projects that user is a member of
    const getMemberProjects = async () => {
      const { data: _getMemberProjects, error } = await supabase
        .from("project_members")
        .select(`project_id`)
        .eq("user_id", userInfo.id);
      if (error) {
        console.error("Error fetching project members: ", error);
      }
      const projectIds = _getMemberProjects
        ? _getMemberProjects.map((project) => project.project_id)
        : [];

      const { data: memberProjects, error: projectsError } = await supabase
        .from("projects")
        .select(
          `*, project_task_status(*), project_tasks(*), project_members(*, user_id(*)), teams(*, team_members(*))`
        )
        .in("id", projectIds);

      if (projectsError) {
        console.error("Error fetching projects:", projectsError);
      }
      return memberProjects || [];
    };

    if (userInfo && !userLoading) {
      Promise.all([getUserProjects(), getMemberProjects()])
        .then(([userProjects, memberProjects]) => {
          setProjects([...userProjects, ...memberProjects]);
          setProjectsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
        });
    }
  }, [userInfo, userLoading]);

  // Add project statuses and members
  useEffect(() => {
    if (!projects) return;

    if (!projectsLoading && projects.length > 0) {
      // add project statuses
      projects.map((project: any) => {
        if (project.id === workspace) {
          setStatuses(project.project_task_status);
        }
      });

      // add members to project
      projects
        ?.find((project: any) => project.id === workspace)
        ?.project_members?.forEach((member: any) => {
          setMembers((prev: any) => [
            ...prev,
            {
            id: member.user_id.id,
            name: member.user_id.full_name,
            full_name: member.user_id.full_name,
            profile_color: member.user_id.profile_color,
            assignee_type: "user",
          }]);
        });

      // add teams to project
      projects
        ?.find((project: any) => project.id === workspace)
        ?.teams?.forEach((team: any) => {
          setMembers((prev: any) => [
            ...prev,
            {
              id: team.id,
              name: team.name,
              full_name: team.name,
              profile_color: "#000000",
              assignee_type: "team",
            },
          ]);
        });
    }
  }, [projectsLoading]);

  /* ================== */
  /* ==== UserData ==== */
  /* ================== */
  return {
    user: userInfo,
    members: members,
    statuses: statuses,
    projects: projects,
    projectsLoading: projectsLoading,
    getUserInfo: async () => await getUserInfo(),
  };
};
export default useUserApi;
