/* eslint-disable @typescript-eslint/no-unused-vars */
import logo from "../../assets/logo.svg";
import todo_icon from "../../assets/Icon-todo.svg";
import projects_icon from "../../assets/Icon-projects.svg";
import team_icon from "../../assets/Icon-team.svg";
import managment_icon from "../../assets/Icon-managemnt.svg";
import canban_icon from "../../assets/Icon-canban-desk.svg";
import task_icon from "../../assets/Icon-tasks.svg";
import ganttchart_icon from "../../assets/Icon-ganttchart.svg";
import calender_icon from "../../assets/Icon-calender.svg";
import contacts_icon from "../../assets/Icon-contacts.svg";
import inbox_icon from "../../assets/Icon-inbox.svg";
import manager_icon from "../../assets/Icon-file-manager.svg";
import copy_icon from "../../assets/Icon-copy.svg";
import team1 from "../../assets/Img.png";
import Option from "../../assets/option.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "../../hooks/user";
import PathConstants from "../../pathConstants";
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useWorkspace } from "../../context/workspace";
import { Modal } from "../modal/Modal";
import { Profile } from "../profile/Profile";
import { copyToClipboard, truncate } from "../../helpers/common";

const Sidebar = ({
  props,
  isOpen,
  setIsOpen,
  isMobile,
}: {
  props?: any;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isMobile?: boolean;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = getUser();
  const { workspaces, loadingWorkspaces } = useWorkspace();
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceSlug, setWorkspaceSlug] = useState("");
  const [workspaceId, setWorkspaceId] = useState("");
  const currentWorkspace =
    sessionStorage.getItem("workspace") ?? workspaces[0]?.id;

  // current workspace
  useEffect(() => {
    if (workspaces.length > 0 && currentWorkspace && !loadingWorkspaces) {
      setWorkspaceName(
        workspaces.find((workspace: any) => workspace.id === currentWorkspace)
          ?.name || ""
      );
      setWorkspaceSlug(
        workspaces.find((workspace: any) => workspace.id === currentWorkspace)
          ?.slug || ""
      );
    }
  }, [workspaces, !loadingWorkspaces]);

  const pageName = location.pathname.split("/")[1];
  const menuItems = [
    {
      overview: {
        name: "Workspace",
        items: [
          {
            name: "Overview",
            icon: projects_icon,
            link: `/${workspaceSlug}/overview`,
          },
          {
            name: "Issues",
            icon: task_icon,
            link: `/${workspaceSlug}/issues`,
          },
          /* {
            name: "Error Logs",
            icon: task_icon,
            link: `/${workspaceSlug}/error-logs`,
          }, */
          /* {
            name: "My Assignments",
            icon: todo_icon,
            link: `/${worksapceSlug}/assigned`,
          }, */
          /* {
            name: "Active",
            icon: projects_icon,
            link: PathConstants.Projects,
          }, */
        ],
      },
      crm: {
        name: "CRM",
        items: [
          {
            name: "Contacts",
            icon: contacts_icon,
            link: `/${workspaceSlug}/contacts`,
          },
        ],
      },
      settings: {
        name: "SETTINGS",
        items: [
          {
            name: "Team",
            icon: team_icon,
            link: `/${workspaceSlug}/team`,
          },
          /* {
            name: "Settings",
            icon: manager_icon,
            link: `/${workspaceSlug}/settings`,
          }, */
          /* {
            name: "Groups",
            icon: task_icon,
            link: "/team",
          } */
          /* {
            name: "Inbox",
            icon: inbox_icon,
            link: PathConstants.Inbox,
          }, */
        ],
      },
      team: {
        name: "Team",
        items: [
          /* {
            name: "Billing",
            icon: manager_icon,
            link: "#",
          }, */
          {
            name: "Settings",
            icon: manager_icon,
            link: "#",
          },
        ],
      },
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (workspace: any) => {
    const workspacePath = location.pathname.split("/")[2];

    // If no workspace is selected then return
    if (!workspace.id) {
      setAnchorEl(null);
      return;
    }

    // Set workspace in session storage
    sessionStorage.setItem("workspace", workspace.id!);
    setWorkspaceName(workspace.name);
    setWorkspaceSlug(workspace.slug);
    setWorkspaceId(workspace.id);
    navigate(`/${workspace.slug}/${workspacePath}`);
    setAnchorEl(null);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(isOpen ?? false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setIsOpen(!isSidebarOpen);
  };

  // Add collapsed state
  const [isCollapsed, setIsCollapsed] = useState(() => {
    return localStorage.getItem("sidebarCollapsed") === "true";
  });

  // Listen for changes to localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setIsCollapsed(localStorage.getItem("sidebarCollapsed") === "true");
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  // Update the sidebar width based on collapsed state
  const sidebarWidth =
    isCollapsed && !isMobile ? "w-[80px]" : "w-full lg:w-[25%] max-w-[250px]";

  return (
    <div className={sidebarWidth}>
      <aside
        className={`fixed z-50 top-0 left-0 ${sidebarWidth} border-r-[1px] border-borderColor dark:border-darkBorderColor h-[100vh] overflow-y-scroll bg-white dark:bg-mainBg transition-all duration-300 ease-in-out`}
      >
        <div className="px-6 h-[100vh] w-full">
          <div className="overflow-auto h-[70vh]">
            <div
              className={`py-8 ${
                isCollapsed && !isMobile ? "px-2" : "px-4"
              } flex items-center font-bold dark:text-darkTextActiveColor text-[13px]`}
            >
              <img src={logo} alt="" className="w-[30px]" />
              {!isCollapsed && <span className="pl-2">Atomlaunch</span>}
            </div>

            {/* Update menu items to handle collapsed state */}
            {workspaces?.length > 0 && (
              <>
                <div className="overview_items mb-10">
                  {menuItems.map((items, index) => (
                    <div key={items.overview.name + index}>
                      {!isCollapsed && (
                        <h6 className="text-textColor dark:text-darkTextColor px-4 pb-3 font-RobotoFlexRegular text-[12px]">
                          {items.overview.name}
                        </h6>
                      )}
                      <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
                        {items.overview.items.map((item, index) => (
                          <Link to={item.link} key={item.name + index}>
                            <li
                              className={`
                            ${
                              location.pathname === item.link
                                ? "text-textColor dark:text-darkTextActiveColor border-[1px] border-[#CFDBD5] border-r-[4px] border-r-mainColor bg-bgLight dark:bg-bgDark dark:border-[#303030] dark:border-r-mainColor"
                                : "text-textColor dark:text-darkTextActiveColor border-[1px] border-transparent hover:bg-transparent hover:border-[1px]"
                            } tab flex items-center text-[14px] py-2 px-4 cursor-pointer rounded gap-3 font-RobotoFlexRegular font-bold
                            ${
                              isCollapsed && !isMobile
                                ? "justify-center px-2"
                                : ""
                            }
                          `}
                            >
                              <img src={item.icon} alt="" className="w-5 h-5" />
                              {!isCollapsed && item.name}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>

                <div className="overview_items mb-10">
                  {menuItems.map((items, index) => (
                    <div key={items.settings.name + index}>
                      {!isCollapsed && (
                        <h6 className="text-textColor dark:text-darkTextColor px-4 pb-3 font-RobotoFlexRegular text-[12px]">
                          {items.settings.name}
                        </h6>
                      )}
                      <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
                        {items.settings.items.map((item, index) => (
                          <Link to={item.link} key={item.name + index}>
                            <li
                              className={`
                            ${
                              location.pathname === item.link
                                ? "text-textColor dark:text-darkTextActiveColor border-[1px] border-[#CFDBD5] border-r-[4px] border-r-mainColor bg-bgLight dark:bg-bgDark dark:border-[#303030] dark:border-r-mainColor"
                                : "text-textColor dark:text-darkTextActiveColor border-[1px] border-transparent hover:bg-transparent hover:border-[1px]"
                            } tab flex items-center text-[14px] py-2 px-4 cursor-pointer rounded gap-3 font-RobotoFlexRegular font-bold
                            ${
                              isCollapsed && !isMobile
                                ? "justify-center px-2"
                                : ""
                            }
                          `}
                            >
                              <img src={item.icon} alt="" className="w-5 h-5" />
                              {!isCollapsed && item.name}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          {/* Update user info section for collapsed state */}
          <div
            className={`absolute bottom-[60px] ${
              isCollapsed && !isMobile ? "w-[90%]" : "w-[85%]"
            }`}
          >
            {workspaces?.length > 0 && !isCollapsed && (
              <div>
                <div className="w-[95%] text-white rounded-md my-2 py-2 px-4 m-auto font-RobotoFlexRegular font-bold text-[14px]">
                  <p className="text-[10px] text-left text-borderColor dark:text-borderColor/50">
                    WORKSPACE
                  </p>
                  <p className="text-left">
                    {
                      workspaces?.filter(
                        (workspace) => workspace.id === currentWorkspace
                      )[0]?.name
                    }
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Profile
          user={user}
          team1={team1}
          Option={Option}
          isCollapsed={isCollapsed && !isMobile}
        />
      </aside>
    </div>
  );
};
export default Sidebar;
