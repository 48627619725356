import { useEffect, useState } from "react";
import { truncate } from "../../helpers/common";
import Overview from "./Overview";
import { PlanDetail } from "./PlanDetail";
import { supabase } from "../../helpers/supabase";

const Dashboard = () => {
  const [logs, setLogs] = useState<any[]>([]);
  const workspace = sessionStorage.getItem("workspace");

  /* ======================== */
  // Fetch Logs
  /* ======================== */
  const fetchLogs = async () => {
    const workspace = sessionStorage.getItem("workspace");
    const { data, error } = await supabase.rpc("get_project_audit_log", {
      p_workspace: workspace,
    });
    if (error) {
      console.error("Error fetching logs:", error);
      return [];
    }
    return data;
  };

  // Fetch Logs from API
  useEffect(() => {
    fetchLogs().then((data) => {
      setLogs(data);
    });
  }, [workspace]);

  return (
    <div className="h-full overflow-x-scroll">
      <div className="h-full max-w-full lg:max-w-7xl">
        <div className="h-[calc(100vh-12rem)] w-full grid gap-4 lg:grid-cols-3 lg:grid-rows-2 m-2">
          <Overview />
          <PlanDetail />
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
            <div className="relative flex h-full flex-col">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10 overflow-hidden h-[calc(100%-20px)]">
                <p className="mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-white max-lg:text-center">
                  Inbox
                </p>
                <hr />

                <ul
                  role="list"
                  className="divide-y divide-gray-100 dark:divide-gray-600 overflow-y-scroll h-[calc(100%-2rem)]"
                >
                  {logs?.map((log, index) => {
                    // date format like hours ago, days ago, months ago
                    const dateFormat = () => {
                      const date = new Date(log.created_at);
                      const currentDate = new Date();
                      const diff = currentDate.getTime() - date.getTime();
                      const hours = Math.floor(diff / (1000 * 60 * 60));
                      const minutes = Math.floor(diff / (1000 * 60));
                      const seconds = Math.floor(diff / 1000);

                      if (seconds < 60) {
                        return `${seconds} seconds ago`;
                      }
                      if (minutes < 60) {
                        return `${minutes} minutes ago`;
                      }
                      if (hours < 24) {
                        return `${hours} hours ago`;
                      }
                      const days = Math.floor(hours / 24);
                      if (days < 30) {
                        return `${days} days ago`;
                      }
                      const months = Math.floor(days / 30);
                      return `${months} months ago`;
                    };

                    const itemMoved =
                      log.old_status_details?.status_id!! &&
                      log.old_status_details?.status_id !==
                        log.new_status_details?.status_id;
                    const operationText = (operation: string) => {
                      switch (operation) {
                        case "INSERT":
                          return "Created";
                        case "UPDATE":
                          return "Updated";
                        case "DELETE":
                          return "Deleted";
                        default:
                          return operation;
                      }
                    };

                    return (
                      <li
                        className="block justify-between gap-x-1 py-5"
                        key={index}
                      >
                        <div className="flex min-w-0 gap-x-1">
                          <div className="min-w-[100%] flex-auto">
                            <div className="text-xs/4 font-semibold text-gray-900">
                              <p className="py-1 truncate text-[12px] text-gray-500 dark:text-gray-300 font-bold">
                                Task: #{log.record_id_details.project_task_id}
                                {truncate(
                                  ` - ${log.record_id_details.name}`,
                                  30
                                )}
                              </p>
                              <span
                                className="py-1 truncate text-[12px] text-secondaryBg font-bold"
                                style={{ color: log.new_status_details?.color }}
                              >
                                {itemMoved
                                  ? truncate(
                                      `Moved from ${log.old_status_details?.status_name} to ${log.new_status_details?.status_name}`,
                                      60
                                    )
                                  : operationText(log.operation)}
                              </span>
                            </div>
                            <p className="flex justify-between mt-1 text-xs text-gray-500 font-medium">
                              by:{" "}
                              {log.user_detail.full_name ??
                                log.user_detail.email}
                              <span>
                                <time>{dateFormat()}</time>
                              </span>
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="relative lg:row-span-2">
            <div className="absolute inset-px bg-bgLight rounded-md dark:text-darkTextActiveColor border-[1px] dark:bg-bgDark dark:border-[#303030] border-[#CFDBD5]"></div>
            <div className="relative flex h-full flex-col overflow-hidden">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-white max-lg:text-center">
                  Reports
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  Here you can see reported issues from your users and issues
                  found by our bug detection system. You can move these issues
                  to the backlog or assign them to a team member.
                </p>
              </div>
              <div className="relative min-h-[30rem] w-full grow">
                <div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-dark shadow-2xl border-l border-1 border-darkBorderColor">
                  <div className="flex ring-1 ring-white/5 bg-darkBorderColor">
                    <div className="flex text-sm/6 font-medium text-gray-400">
                      <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                        Setup.tsx
                      </div>
                      <div className="border-r border-white/20 px-4 py-2">
                        BugPilot.jsx
                      </div>
                    </div>
                  </div>
                  <div className="px-5 pb-14 pt-6 text-white/50 text-xs">

                  Coming Soon
                    {/* <pre className="whitespace-pre-wrap ">{`<script src="https://zp1v56uxy8rdx5ypatb0ockcb9tr6a-oci3--5173--d3acb9e1.local-credentialless.webcontainer-api.io/error-tracker.js"></script>
  
<script>
  ErrorTracker.init('9c92056a-d952-43c9-9843-b835de63f47b');
</script>`}</pre> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
