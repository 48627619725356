import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import { ProfileModal } from "../components/modal/ProfileModal";
import { supabase } from "../helpers/supabase";
import { ProfileImage } from "./profile/profileImage";
import { getUser } from "../hooks/user";
import TaskModal from "../components/modal/TaskModal";

export default function Navbar({
  title,
  hideActions,
  btnAction,
  btnText = "+ New",
}: {
  title?: string | null;
  hideActions?: boolean;
  btnAction?: () => void;
  btnText?: string;
}) {
  const { user } = getUser();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = React.useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notifications] = useState([
    { id: 1, text: "John assigned you a new task", time: "2 hours ago" },
    { id: 2, text: "Your task 'Update homepage' is due tomorrow", time: "5 hours ago" },
    { id: 3, text: "Team meeting in 30 minutes", time: "30 minutes ago" },
  ]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    return localStorage.getItem("sidebarCollapsed") === "true";
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const profileMenuItem = ({ title, action } : { title: string, action: () => void } ) => {
    return (
      <button
        onClick={() => action()}
        className="block w-full px-4 py-3 text-[18px] text-gray-700 font-[600] dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
        >
        {title}
      </button>
    );
  };

  const handleMenuItemClick = (action: () => void) => {
    action();
    setIsDropdownOpen(false);
  }

  const handleCreateTask = () => {
    setIsTaskModalOpen(true);
  };

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed(prev => {
      const newState = !prev;
      localStorage.setItem("sidebarCollapsed", String(newState));
      return newState;
    });
  }, []);

  return (
    <>
      {/* Desktop Navbar */}
      <div className="dark:bg-mainBg bg-[#E9E9F2] border-b-[1px] border-borderColor dark:border-darkBorderColor hidden z-10 lg:block fixed w-[calc(100%-250px)]" style={{ width: isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 250px)', transition: 'width 0.3s ease-in-out' }}>
        <div className="py-4 px-4 mx-auto flex justify-between">
          <div className="flex items-center gap-4">
            {/* <button
              onClick={handleToggleCollapse}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button> */}
            <p className="font-RobotoFlexRegular flex items-center gap-2 font-[800] text-[18px] text-black dark:text-darkTextColor">
              {title}
            </p>
          </div>

          <div className="flex items-center gap-4">
            {!hideActions && (
              <>
                {/* <button
                  onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full relative"
                >
                  <div className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></div>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                </button> */}

                <button
                  onClick={btnAction || handleCreateTask}
                  className="font-RobotoFlexRegular font-[700] text-[12px] text-white bg-[#0052FF] hover:shadow-md py-2 px-8 rounded-lg"
                >
                  {btnText}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="bg-mainBg dark:bg-dark lg:hidden w-[100%] mx-auto px-5 py-5 fixed top-0 z-50 flex items-center justify-between">
        <div className="flex items-center gap-1">
        <div className="p-3 hover:bg-[#0052FF] hover:text-white rounded-lg" onClick={toggleSidebar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <title>menu-8</title>
            <g fill="#ffffff">
              <path d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z"></path>
              <path d="M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z"></path>
              <path d="M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z"></path>
            </g>
          </svg>
        </div>

        <p className="self-center font-RobotoFlexRegular font-[800] text-[18px] text-black dark:text-darkTextColor">
          {title || "All Projects"}
        </p>
        </div>

        <div className="relative">
          <div className="flex items-center gap-4">
            {!hideActions && (
              <button
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full relative"
              >
                <div className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></div>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
              </button>
            )}
            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="cursor-pointer">
              {user && <ProfileImage user={user} small={false} classes="w-[40px] h-[40px]" />}
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar */}
      {isSidebarOpen && (
        <>
          <div className="lg:hidden fixed top-0 left-0 z-50 w-[250px] lg:w-[280px] h-full bg-white dark:bg-mainBg shadow-lg transition-transform duration-300 ease-in-out">
            <div className="p-4 border-b dark:border-darkBorderColor">
              <button 
                onClick={toggleSidebar}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          </div>

          {/* Overlay */}
          <div 
            className="lg:hidden fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out backdrop-blur-sm" 
            onClick={toggleSidebar}
          ></div>
        </>
      )}

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute z-40 top-20 mt-2 w-full bg-white dark:bg-dark rounded shadow-lg">
          <div className="text-center ">
          { profileMenuItem({ title: "Profile", action: () => handleMenuItemClick(() => setIsProfileModalOpen(true)) }) }
          { profileMenuItem({ title: "Log Out", action: () => handleMenuItemClick(() => supabase.auth.signOut()) }) }
          </div>
          
        </div>
      )}

      <ProfileModal user={user} setIsProfileModalOpen={setIsProfileModalOpen} isProfileModalOpen={isProfileModalOpen} />

      {/* Notification Panel */}
      <div className={`fixed top-0 right-0 h-full w-80 bg-white dark:bg-dark shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${isNotificationOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="p-4 border-b dark:border-darkBorderColor">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold dark:text-white">Notifications</h2>
            <button 
              onClick={() => setIsNotificationOpen(false)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 dark:text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        
        <div className="overflow-y-auto h-full pb-20">
          {notifications.map((notification) => (
            <div key={notification.id} className="p-4 border-b dark:border-darkBorderColor hover:bg-gray-50 dark:hover:bg-gray-800">
              <p className="text-sm dark:text-white">{notification.text}</p>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">{notification.time}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Overlay for notification panel */}
      {isNotificationOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsNotificationOpen(false)}
        ></div>
      )}

      <TaskModal isOpen={isTaskModalOpen} onClose={() => setIsTaskModalOpen(!isTaskModalOpen)} />
    </>
  );
}