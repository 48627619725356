import { useEffect, useState } from "react";

export const truncate = (str: string, n: number) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const CalculateTimeUntil = ({ date }: { date: Date }) => {
  const [dateMessage, setDateMessage] = useState<string>("");
  const [dateColor, setDateColor] = useState<string>("");

  const handleDateFormat = (date: Date) => {
    if (!date) return;
    const now = new Date();
    const newDate = new Date(date);
    const diff = newDate.getTime() - now.getTime(); // Removed ?. since we checked for !date
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (diff < 0) {
      // If the difference is negative, the date has passed
      setDateMessage("Past Due");
      setDateColor("text-red-800");
    } else if (days < 1) {
      setDateMessage("Today");
      setDateColor("text-red-500");
    } else if (days < 2) {
      setDateMessage("Tomorrow");
      setDateColor("text-orange-500");
    } else if (days < 7) {
      setDateMessage(`${days} days`);
      setDateColor("text-yellow");
    } else if (months < 1) {
      setDateMessage(`${days} days`);
      setDateColor("text-blue");
    } else if (years < 1) {
      setDateMessage(`${days} days`);
      setDateColor("text-purple-500"); // Added color here
    } else {
      setDateMessage(`${days} days`);
      setDateColor("text-gray-500");
    }
  };

  useEffect(() => {
    handleDateFormat(date);
  }, [date]);

  return (
    <span className={dateColor}>{dateMessage}</span>
  );
};
