import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../helpers/supabase';
import { useWorkspace } from '../context/workspace';
import { BiError, BiSearch } from 'react-icons/bi';
import { AiOutlineWarning, AiOutlineClockCircle } from 'react-icons/ai';
import { FiAlertCircle, FiPlus, FiFilter } from 'react-icons/fi';

interface ErrorLog {
  id: string;
  created_at: string;
  error_message: string;
  stack_trace: string;
  user_id: string;
  severity: 'error' | 'warning' | 'info';
  browser?: string;
  os?: string;
}

const MOCK_ERRORS: ErrorLog[] = [
  {
    id: '1',
    created_at: new Date().toISOString(),
    error_message: 'TypeError: Cannot read property "length" of undefined',
    stack_trace: `TypeError: Cannot read property "length" of undefined
    at processData (app.js:123)
    at async Function.handleRequest (api.js:45)`,
    user_id: '123',
    severity: 'error',
    browser: 'Chrome 98.0.4758.102',
    os: 'Windows 10'
  },
  {
    id: '2',
    created_at: new Date(Date.now() - 3600000).toISOString(),
    error_message: 'Failed to fetch data from API endpoint',
    stack_trace: `GET https://api.example.com/data 404 (Not Found)
    at fetchData (services.js:67)
    at loadUserData (users.js:89)`,
    user_id: '124',
    severity: 'warning',
    browser: 'Firefox 97.0',
    os: 'macOS 12.2.1'
  }
];

export default function ErrorLogs() {
  const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSeverity, setSelectedSeverity] = useState<string>('all');
  const [timeRange, setTimeRange] = useState<string>('24h');
  const [expandedLog, setExpandedLog] = useState<string | null>(null);
  
  const navigate = useNavigate();
  const { workspaces } = useWorkspace();
  const currentWorkspace = sessionStorage.getItem("workspace");

  useEffect(() => {
    fetchErrorLogs();
  }, []);

  const fetchErrorLogs = async () => {
    try {
      setErrorLogs(MOCK_ERRORS);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLogs = errorLogs.filter(log => {
    const matchesSearch = log.error_message.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         log.stack_trace.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSeverity = selectedSeverity === 'all' || log.severity === selectedSeverity;
    
    // Time range filter logic
    const logDate = new Date(log.created_at);
    const now = new Date();
    const hoursDiff = (now.getTime() - logDate.getTime()) / (1000 * 60 * 60);
    
    const matchesTime = timeRange === 'all' || 
                       (timeRange === '24h' && hoursDiff <= 24) ||
                       (timeRange === '7d' && hoursDiff <= 168) ||
                       (timeRange === '30d' && hoursDiff <= 720);

    return matchesSearch && matchesSeverity && matchesTime;
  });

  const getSeverityIcon = (severity: string) => {
    switch (severity) {
      case 'error':
        return <BiError className="text-red-500 text-xl" />;
      case 'warning':
        return <AiOutlineWarning className="text-yellow-500 text-xl" />;
      case 'info':
        return <FiAlertCircle className="text-blue-500 text-xl" />;
      default:
        return null;
    }
  };

  const getSeverityClass = (severity: string) => {
    switch (severity) {
      case 'error':
        return 'border-l-4 border-red-500';
      case 'warning':
        return 'border-l-4 border-yellow-500';
      case 'info':
        return 'border-l-4 border-blue-500';
      default:
        return '';
    }
  };

  const createTask = async (log: ErrorLog) => {
    try {
      const { data: issue, error: issueError } = await supabase
        .from('issues')
        .insert([
          {
            title: `Error: ${log.error_message.substring(0, 100)}`,
            description: `Error Message: ${log.error_message}\n\nStack Trace:\n${log.stack_trace}`,
            status: 'open',
            workspace_id: currentWorkspace,
            priority: 'high',
            type: 'bug'
          }
        ])
        .select()
        .single();

      if (issueError) throw issueError;
      
      const workspace = workspaces.find((w: any) => w.id === currentWorkspace);
      navigate(`/${workspace?.slug}/issues`);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300"></div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Header and Filters */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-100">Error Logs</h1>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-400">
              {filteredLogs.length} {filteredLogs.length === 1 ? 'error' : 'errors'} found
            </span>
          </div>
        </div>

        {/* Search and Filters */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-1 md:col-span-2">
            <div className="relative">
              <BiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search errors..."
                className="w-full pl-10 pr-4 py-2 bg-darkBorderColor rounded-md text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          
          <select
            className="bg-darkBorderColor text-gray-200 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={selectedSeverity}
            onChange={(e) => setSelectedSeverity(e.target.value)}
          >
            <option value="all">All Severities</option>
            <option value="error">Errors</option>
            <option value="warning">Warnings</option>
            <option value="info">Info</option>
          </select>

          <select
            className="bg-darkBorderColor text-gray-200 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
          >
            <option value="24h">Last 24 hours</option>
            <option value="7d">Last 7 days</option>
            <option value="30d">Last 30 days</option>
            <option value="all">All time</option>
          </select>
        </div>
      </div>

      {/* Error Cards */}
      <div className="space-y-4">
        {filteredLogs.map((log) => (
          <div 
            key={log.id} 
            className={`bg-darkBorderColor rounded-lg transition-all hover:shadow-lg ${getSeverityClass(log.severity)}`}
          >
            <div className="p-4 cursor-pointer" onClick={() => setExpandedLog(expandedLog === log.id ? null : log.id)}>
              <div className="flex items-start space-x-4">
                <div className="mt-1">
                  {getSeverityIcon(log.severity)}
                </div>
                <div className="flex-grow">
                  <div className="flex justify-between items-start">
                    <div className="font-medium text-gray-200 mb-1">{log.error_message}</div>
                    <div className="flex items-center text-sm text-gray-400">
                      <AiOutlineClockCircle className="mr-1" />
                      {new Date(log.created_at).toLocaleString()}
                    </div>
                  </div>
                  <div className="text-sm text-gray-400">
                    {log.browser} • {log.os}
                  </div>
                </div>
              </div>
            </div>

            {/* Expanded Content */}
            {expandedLog === log.id && (
              <div className="px-4 pb-4">
                <pre className="bg-mainBg p-4 rounded-md text-sm overflow-x-auto text-gray-300 mb-4">
                  {log.stack_trace}
                </pre>
                <button
                  onClick={() => createTask(log)}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-sm font-medium text-white rounded-md hover:bg-blue-700 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FiPlus className="mr-2 h-4 w-4" />
                  Create Task
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {filteredLogs.length === 0 && (
        <div className="text-center py-12 bg-darkBorderColor rounded-lg">
          <FiAlertCircle className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-400">No error logs found</h3>
          <p className="mt-1 text-sm text-gray-500">
            {searchTerm || selectedSeverity !== 'all' 
              ? 'Try adjusting your filters'
              : 'Your application is running smoothly with no recorded errors.'}
          </p>
        </div>
      )}
    </div>
  );
} 