import { useEffect, useState } from "react";
import closeIcon from "../../assets/close.svg";
import { supabase } from "../../helpers/supabase";
import { useUser } from "../../context/userInfo";
import { Editor } from "../tiptap/Editor";
import { useWorkspace } from "../../context/workspace";
import { ProfileImage } from "../profile/profileImage";
import { stat } from "fs";
import { motion, AnimatePresence } from "framer-motion";

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    y: 20
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.3
    }
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    y: 20,
    transition: {
      duration: 0.2
    }
  }
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

const TaskModal = ({
  defaultStatus,
  isOpen,
  onClose,
  children,
}: {
  defaultStatus?: number;
  isOpen: any;
  onClose: any;
  children?: any;
}) => {
  const { user, projects, members, statuses } = useUser();
  const modalClass = isOpen ? "block" : "hidden";
  const workspace = sessionStorage.getItem("workspace");
  const [taskAssignees, setTaskAssignees] = useState<any>([]);

  const [task, setTask] = useState<any>([]);
  const maxAssignees = 3;
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [membersDropdown, setMembersDropdown] = useState(false);
  const [priorityDropdown, setPriorityDropdown] = useState(false);
  const [priorityList, setPriorityList] = useState([
    { id: 0, name: "No Priority", icon: "priority-0.svg" },
    { id: 1, name: "Urgent", icon: "priority-1.svg" },
    { id: 2, name: "High", icon: "priority-2.svg" },
    { id: 3, name: "Medium", icon: "priority-3.svg" },
    { id: 4, name: "Low", icon: "priority-4.svg" },
  ]);

  useEffect(() => {
    setTask((prev: any) => {
      return {
        ...prev,
        assignees: taskAssignees.map((assignee: any) => {
          return {
            assignee_type: assignee.assignee_type,
            team_id: assignee.assignee_type === "team" ? assignee.id : null,
            user_id: assignee.assignee_type === "user" ? assignee.id : null,
          };
        }),
      };
    });
  }, [taskAssignees]);

  /* ================= */
  // Default task data
  /* ================= */
  useEffect(() => {
    setTask({
      name: "",
      content: "",
      due_date: null,
      priority: 0,
      status: statuses?.find(
        (status: any) => status.status_id === defaultStatus
      )?.status_id,
      assignee: user.id,
      assignee_type: "user",
    });
  }, [defaultStatus]);

  /* ======================== */
  // Handle Dropdowns
  /* ======================== */
  const handleDropdown = (type: string) => {
    if (type === "status") {
      setStatusDropdown(!statusDropdown);
      setMembersDropdown(false);
      setPriorityDropdown(false);
    } else if (type === "members") {
      setMembersDropdown(!membersDropdown);
      setStatusDropdown(false);
      setPriorityDropdown(false);
    } else if (type === "priority") {
      setPriorityDropdown(!priorityDropdown);
      setStatusDropdown(false);
      setMembersDropdown(false);
    }
  };

  /* ======================== */
  // Set task data if updateTask is available
  /* ======================== */
  /* useEffect(() => {
    if (updateTask) {
      setTask(updateTask);
      setTaskAssignees(
        updateTask.project_task_assignees?.map((assignee: any) => {
          return assignee.assignee_type === "team"
            ? {
                id: assignee.team_id?.id,
                name: assignee.team_id?.name,
                full_name: assignee.team_id?.name,
                profile_color: assignee.team_id?.profile_color ?? "#000",
                assignee_type: "team",
              }
            : {
                id: assignee.user_id?.id ?? assignee.assignee_id?.id,
                name:
                  assignee.user_id?.full_name ??
                  assignee.assignee_id?.full_name,
                full_name:
                  assignee.user_id?.full_name ??
                  assignee.assignee_id?.full_name,
                profile_color:
                  assignee.user_id?.profile_color ??
                  assignee.assignee_id?.profile_color ??
                  "#000",
                assignee_type: "user",
              };
        })
      );
    }
  }, [updateTask]); */

  /* ======================== */
  // Handle Content Change
  /* ======================== */
  const handleContentChange = (content: string) => {
    setTask((prevTask: any) => ({
      ...prevTask,
      content: content,
    }));
  };

  const handleInputChanges = (e: any) => {
    const { id, value } = e.target;
    if (id === "taskName") {
      setTask({
        ...task,
        name: value,
      });
    } else if (id === "due_date") {
      setTask({
        ...task,
        due_date: value,
        enable_due_date: true,
      });
    } else if (id === "priority") {
      setTask({
        ...task,
        priority: value,
      });
    }
  };

  /* ======================== */
  /* ==== Task Creation ===== */
  /* ======================== */
  const handleTaskCreation = async () => {
    if (!task.name || !workspace) {
      alert("Please fill all fields");
      return;
    }

    const { error } = await supabase.rpc("create_task", {
      p_workspace: workspace,
      p_task: {
        ...task,
      },
    });

    if (error) {
      console.error("Error fetching user data: ", error);
    }

    if (!error) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/50 z-50"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          <motion.div
            className="fixed left-[30%] top-[30%] bg-bgDark w-[95%] md:w-[70%] lg:w-[40%] xl:w-[40%] rounded-lg z-50 shadow-lg border-[2px] border-borderColor dark:border-darkBorderColor/50"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {/* header menu with exit icon */}
            <div className="flex justify-between items-center w-full border-b-[1px] border-borderColor dark:border-darkBorderColor">
              <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li className="me-2">
                  <div className="inline-block p-4 text-blue-600 rounded-t-lg active dark:text-blue-500 border-b-2 border-mainColor text-white">
                    Task
                  </div>
                </li>
              </ul>

              <button
                className="text-gray-400 bg-transparent hover:bg-gray-200/10 dark:hover:bg-gray-600/30 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:text-white m-2"
                onClick={onClose}
              >
                <img src={closeIcon} alt="close" className="opacity-30" />
              </button>
            </div>

            {/* Modal content */}
            <div className="p-6">
              <div className="grid grid-cols-1 py-2">
                <input
                  className="text-md text-white bg-transparent font-medium outline-none focus:outline-none"
                  type="text"
                  placeholder="Task Name"
                  onChange={(e) => handleInputChanges(e)}
                  value={task.name}
                  id="taskName"
                />
              </div>

              {/* content */}
              <Editor
                defaultContent={task.content}
                setTaskDescription={(content) => handleContentChange(content)}
              />

              {/* Assignees */}
              <div className="grid grid-cols-4 gap-4 py-4">
                <p className="text-white text-sm font-medium col-span-4">
                  Assignees{" "}
                  <span className="text-borderColor dark:text-gray-500">
                    ({taskAssignees.length ?? 0}/{maxAssignees})
                  </span>
                </p>
                {taskAssignees.map((assignee: any) => {
                  return (
                    <ProfileImage
                      user={assignee}
                      pill={true}
                      small={false}
                      show_name={false}
                      classes={`cursor-pointer grid grid-rows-subgrid gap-4 row-span-2 text-center border-2 border-mainColor dark:border-darkBorderColor/50 rounded-md`}
                      onClick={() => {
                        setTaskAssignees(
                          taskAssignees.filter((t: any) => t.id !== assignee.id)
                        );
                        /* setTasks(
                            tasks.map((task: any) =>
                              task.task_id === taskId
                                ? {
                                    ...task,
                                    project_task_assignees:
                                      task.project_task_assignees.filter(
                                        (t: any) =>
                                          t.assignment_id !== assignee.assignment_id
                                      ),
                                  }
                                : task
                            )
                          ); */
                        /* api.removeMemberFromTask({
                            assignment_id: assignee.assignment_id,
                          }); */
                      }}
                    />
                  );
                })}
              </div>

              {/* Actions */}
              <div className="flex flex-wrap items-start gap-2 border-t-2 border-b-2 border-borderColor dark:border-darkBorderColor/40 py-3">
                {/*=================*/}
                {/*== Task Status ==*/}
                {/*=================*/}
                <div
                  className="relative inline-flex items-center rounded-md bg-darkBorderColor/10 px-2 py-2 text-xs font-medium text-white/50 ring-1 ring-inset ring-white/10"
                  style={{
                    background: statuses?.find(
                      (status: any) => status.status_id === task.status
                    )?.color,
                  }}
                >
                  <div>
                    <button
                      type="button"
                      id="status-menu"
                      aria-expanded="true"
                      aria-haspopup="true"
                      onClick={() => handleDropdown("status")}
                    >
                      <span className="text-white/50">
                        {task?.status
                          ? statuses?.map((status: any) => {
                              if (status?.status_id === task?.status) {
                                return status?.status_name;
                              }
                            })
                          : (statuses && statuses[0]?.status_name) ?? "No Status"}
                      </span>
                    </button>
                  </div>

                  <div
                    className="origin-top-right absolute left-0 top-7 mt-2 w-56 max-h-[250px] overflow-y-scroll rounded-md shadow-lg bg-bgDark ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-borderColor dark:border-darkBorderColor/50 rounded-l"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    hidden={!statusDropdown}
                  >
                    <div className="py-1" role="none">
                      {statuses?.map((status: any) => (
                        <div
                          key={status.status_id}
                          className="flex items-center gap-2 px-4 py-2 text-sm text-white/50 hover:bg-gray-700/10 hover:text-white cursor-pointer"
                          role="menuitem"
                          onClick={() => {
                            setTask({
                              ...task,
                              status: status.status_id,
                            });
                            handleDropdown("status");
                          }}
                        >
                          <div
                            className={`flex-shrink-0 w-2.5 h-2.5 bg-${
                              status.color ? status.color : "blue"
                            } rounded-full border border-1 border-gray-600`}
                            style={{ backgroundColor: status.color }}
                          ></div>
                          {status.status_name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/*=================*/}
                {/*==== Assignee ===*/}
                {/*=================*/}
                <div className="relative inline-flex items-center rounded-md bg-darkBorderColor/10 px-2 py-2 text-xs font-medium text-white/50 ring-1 ring-inset ring-white/10">
                  <div>
                    <button
                      type="button"
                      id="assignee-menu"
                      aria-expanded="true"
                      aria-haspopup="true"
                      onClick={() => {
                        handleDropdown("members");
                      }}
                    >
                      <span className="text-white/50">Assignee</span>
                    </button>
                  </div>

                  <div
                    className="origin-top-right absolute left-0 top-7 mt-2 w-56 max-h-[250px] overflow-y-scroll rounded-md shadow-lg bg-bgDark ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-borderColor dark:border-darkBorderColor/50 rounded-l"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    hidden={!membersDropdown}
                  >
                    <div className="py-1" role="none">
                      {members
                        ?.filter(
                          (member: any) =>
                            member.id !==
                            taskAssignees.find((t: any) => t.id === member.id)?.id
                        )
                        .map((member: any) => (
                          <div
                            key={member.id}
                            className="flex items-center gap-2 px-4 py-2 text-sm text-white/50 hover:bg-gray-700/10 hover:text-white cursor-pointer"
                            role="menuitem"
                            onClick={(e) => {
                              setTask({
                                ...task,
                                assignee: member.id,
                                assignee_type: member.assignee_type,
                              });
                              /* handleDropdown("members"); */
                              if (
                                taskAssignees.find(
                                  (assignee: any) => assignee.id === member.id
                                ) ||
                                taskAssignees.length === maxAssignees
                              ) {
                                return;
                              }

                              setTaskAssignees((prev: any) => [...prev, member]);
                            }}
                          >
                            <div
                              className={`flex-shrink-0 w-2.5 h-2.5 bg-${
                                member.profile_color
                                  ? member.profile_color
                                  : "blue"
                              } rounded-full border border-1 border-gray-600`}
                              style={{
                                backgroundColor: member.profile_color,
                              }}
                            ></div>
                            {member.full_name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                {/*=================*/}
                {/*=== Priority ====*/}
                {/*=================*/}
                <div className="relative inline-flex items-center rounded-md bg-darkBorderColor/10 px-2 py-2 text-xs font-medium text-white/50 ring-1 ring-inset ring-white/10">
                  <button
                    type="button"
                    id="priority-menu"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => handleDropdown("priority")}
                  >
                    <span className="text-white/50 flex items-center gap-2">
                      <img
                        src={`/assets/svgs/priority-${
                          task?.priority ?? priorityList[0].id
                        }.svg`}
                        className="w-4"
                      />
                      {priorityList.filter(
                        (action: any) => action.id === task?.priority
                      )[0]?.name ?? "No Priority"}
                    </span>
                  </button>

                  <div
                    className="origin-top-right absolute left-0 top-7 mt-2 w-56 max-h-[250px] overflow-y-scroll rounded-md shadow-lg bg-bgDark ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-borderColor dark:border-darkBorderColor/50 rounded-l"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    hidden={!priorityDropdown}
                  >
                    <div className="py-1" role="none">
                      {priorityList.map((priority: any) => (
                        <div
                          key={priority.id}
                          className="flex items-center gap-2 px-4 py-2 text-sm text-white/50 hover:bg-gray-700/10 hover:text-white cursor-pointer"
                          role="menuitem"
                          onClick={() => {
                            setTask({
                              ...task,
                              priority: priority.id,
                            });
                            handleDropdown("priority");
                          }}
                        >
                          <img
                            src={`/assets/svgs/priority-${priority?.id}.svg`}
                            className="w-4"
                          />
                          {priority.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/*=================*/}
                {/*==== Due Date ===*/}
                {/*=================*/}
                <input
                  type="date"
                  id="due_date"
                  name="due_date"
                  className="inline-flex items-center rounded-md bg-darkBorderColor/10 px-2 py-2 text-xs font-medium text-white/50 ring-1 ring-inset ring-white/10"
                  onChange={(e) => handleInputChanges(e)}
                  value={task?.due_date}
                />
              </div>

              {/* Create button */}
              <div className="flex justify-end mt-6">
                <button
                  onClick={() => handleTaskCreation()}
                  className="bg-mainColor hover:bg-mainColor/90 transition-colors rounded-md px-4 py-2 text-white font-bold text-sm"
                >
                  Create Task
                </button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default TaskModal;
