import { Formik } from "formik";
import { Modal } from "../modal/Modal";
import { useState } from "react";
import { supabase } from "../../helpers/supabase";
import { getUser } from "../../hooks/user";

interface inputValues {
  fullName: string;
  profileColor: string;
  profession: string;
  bio: string;
}

export function ProfileModal({
  user,
  setIsProfileModalOpen,
  isProfileModalOpen,
}: {
  user: any;
  setIsProfileModalOpen: any;
  isProfileModalOpen: any;
}) {

  const { getUserInfo } = getUser();

  return (
    <Modal
      open={isProfileModalOpen}
      setOpen={setIsProfileModalOpen}
      title=""
      children={
        <>
          <div className="w-full">
            <div className="">
              <div className="w-full">
                <Formik
                  initialValues={{
                    fullName: user?.full_name ?? "",
                    profileColor: user?.profile_color ?? "",
                    profession: "",
                    bio: "",
                  }}
                  onSubmit={async (values: inputValues) => {
                    const { fullName, profileColor, profession, bio } =
                      values;
                    const { data, error } = await supabase.from("users").upsert({
                      id: user.id,
                      full_name: fullName,
                      profile_color: profileColor,
                      //profession: profession,
                      //bio: bio,
                    });

                    if (error) {
                      console.log("Error updating profile:", error.message);
                    } else {
                      console.log("Profile updated successfully:", data);
                      // update user object with new data
                      user.full_name = fullName;
                      user.profile_color = profileColor;
                      await getUserInfo();
                      setIsProfileModalOpen(false);
                    }
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="grid max-w-2xl mx-auto mt-8"
                    >
                      <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
                        {/* <img
                          className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500 mx-auto"
                          src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGZhY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                          alt="Bordered avatar"
                        /> */}

                        <div
                          style={{
                            backgroundColor: `${
                              user?.profile_color ? `#${user?.profile_color?.split("#").join("")}` : "000"
                            }`,
                          }}
                          className={`object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500 mx-auto border border-[#CFDBD5] flex justify-center items-center text-white text-[42px] font-bold`}
                        >
                          {user?.full_name
                            ? user?.full_name
                                .split(" ")
                                .map((name: string) => name[0])
                                .join("")
                            : ""}
                        </div>

                        {/* <div className="flex flex-col space-y-5 sm:ml-8">
                          <button
                            type="button"
                            className="py-3.5 px-7 text-base font-medium text-indigo-100 focus:outline-none bg-[#202142] rounded-lg border border-indigo-200 hover:bg-indigo-900 focus:z-10 focus:ring-4 focus:ring-indigo-200 "
                          >
                            Change picture
                          </button>
                          <button
                            type="button"
                            className="py-3.5 px-7 text-base font-medium text-indigo-900 focus:outline-none bg-white rounded-lg border border-indigo-200 hover:bg-indigo-100 hover:text-[#202142] focus:z-10 focus:ring-4 focus:ring-indigo-200 "
                          >
                            Delete picture
                          </button>
                        </div> */}
                      </div>

                      <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                        <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                          <div className="w-full">
                            <input
                              type="text"
                              id="fullName"
                              className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                              placeholder={user?.full_name ?? "Full Name"}
                              defaultValue={user?.full_name}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="w-full">
                            <input
                              type="color"
                              id="profileColor"
                              className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                              placeholder={`${user?.profile_color ?? "Profile Color"}`}
                              defaultValue={user?.profile_color}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="mb-2 sm:mb-6">
                          <input
                            type="email"
                            id="email"
                            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                            placeholder={`${user?.email} - Can't be changed`}
                            disabled
                          />
                        </div>

                        {/* <div className="mb-2 sm:mb-6">
                          <input
                            type="text"
                            id="profession"
                            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                            placeholder="your profession"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-6">
                          <textarea
                            id="bio"
                            rows={4}
                            className="block p-2.5 w-full text-sm text-indigo-900 bg-indigo-50 rounded-lg border border-indigo-300 focus:ring-indigo-500 focus:border-indigo-500 "
                            placeholder="Write your bio here..."
                            onChange={handleChange}
                          ></textarea>
                        </div> */}

                        <div className="flex justify-end">
                          <button
                            onClick={() => setIsProfileModalOpen(false)}
                            className="text-white mr-5 bg-red-500  hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-mainColor dark:hover:bg-mainColorHover dark:focus:blueHover"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
}
