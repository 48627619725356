import React, { createContext, ReactNode, useEffect } from "react";
import useUserApi from "./useUserApi";

export interface Members {
  id: string;
  name: string;
  full_name: string;
  profile_color: string;
  assignee_type: string;
}

export interface Status {
  status_id: string;
  status_name: string;
  color: string;
}

export interface UserData {
  lastName: ReactNode;
  firstName: ReactNode;
  id: string;
  aud: string;
  role: string;
  full_name: string;
  user_metadata: {
    first_name: string;
    last_name: string;
  };
  email: string;
  email_confirmed_at: string;
  profile_color: string;
  user_plan: {
    status: string;
    plan_id: {
      name: string;
      user_limit: number;
      team_limit: number;
      max_tasks_per_project: number;
      max_projects: number;
      price: number;
      plan_id: number;
    };
  };
  teams: [
    {
      members: [];
      team_count: number;
      team_id: string;
      team_name: string;
    }
  ];
}

interface UserContextValue<T> {
  user: UserData;
  members: Members[];
  statuses: Status[];
  projects: any;
  projectsLoading: boolean;
  getUserInfo: () => void;
}

const ConnectUserContext = createContext<UserContextValue<UserData>>({
  user: {
    id: "",
    aud: "",
    role: "",
    full_name: "",
    user_metadata: {
      first_name: "",
      last_name: "",
    },
    email: "",
    email_confirmed_at: "",
    profile_color: "",
    lastName: undefined,
    firstName: undefined,
    user_plan: {
      status: "",
      plan_id: {
        name: "",
        user_limit: 0,
        team_limit: 0,
        max_tasks_per_project: 0,
        max_projects: 0,
        price: 0,
        plan_id: 0,
      },
    },
    teams: [
      {
        members: [],
        team_count: 0,
        team_id: "",
        team_name: "",
      },
    ],
  },
  members: [
    {
      id: "",
      name: "",
      full_name: "",
      profile_color: "",
      assignee_type: "",
    },
  ],
  statuses: [
    {
      status_id: "",
      status_name: "",
      color: "",
    },
  ],
  projects: [],
  projectsLoading: true,
  getUserInfo: async () => {},
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  let { user, members, statuses, projects, projectsLoading, getUserInfo } =
    useUserApi();

  return (
    <ConnectUserContext.Provider
      value={{
        user,
        members,
        statuses,
        projects,
        projectsLoading,
        getUserInfo: async () => await getUserInfo(),
      }}
    >
      {children}
    </ConnectUserContext.Provider>
  );
}

export function useUser() {
  return React.useContext(ConnectUserContext);
}
